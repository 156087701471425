var $ = jQuery.noConflict(),
  CHAM = CHAM || {};

(function ($) {
  'use strict';

  var log = debug('cham:debug');

  // global logging
  CHAM.log = debug('cham:log');
  CHAM.info = debug('cham:info');
  CHAM.error = debug('cham:error');

  CHAM.debug = {
    color: {
      green:  'color: #5cb85c;'
      , red:    'color: #c9302c;'
      , blue:   'color: #286090;'
      , yellow: 'color: #f0ad4e;'
    },
    init: function init() {
      if (window.location.hostname === 'localhost' && !log.enabled) {
        console.log('To enable debugging enter%c CHAM.debug.enable %cin the console'
          , this.color.green + ' font-size: 15px; font-weight: bold;', ''
        );
      }
      log('%c🚨 %cDebug Enabled %c🚨', this.color.red + 'font-size: 25px', 'font-size: 25px'
        , this.color.red + 'font-size: 25px'
      );
      log('To disable debugging enter%c CHAM.debug.disable %cin the console'
        , this.color.yellow + ' font-size: 15px; font-weight: bold;', ''
      );
      window.setTimeout(function() {
        log('CHAM.DATA', CHAM.DATA);
      }, 3000);
    },
    enable: function enable() {},
    disable: function disable() {}
  };
})(jQuery);

CHAM.debug.enable.toString = function() {
  debug.enable('cham:*');
  console.log('(%c✔%c) Debugging Enabled', CHAM.debug.color.green, '');
  return '';
};
CHAM.debug.disable.toString = function() {
  debug.disable('cham:*');
  console.log('(%c✘%c) Debugging Disabled', CHAM.debug.color.red, '');
  return '';
};
