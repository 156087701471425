(function () {
    'use strict';

    var log = debug('cham:visualizer'),
        $landing = $('nav.landing, section.landing'),
        $scenes = $('section.scenes'),
        $visualizer = $('div.visualizer-wrapper');
    var createComponent = Utilities.createComponent;

  var loadingViz = function () {
      var img = document.createElement('img');
      $(img).css({ position: 'absolute', left: 0, top: 0, 'z-index': 0 });
      LoadingMsg.startImg(img, 'loadViz', document.getElementsByTagName('body'), null);
    }

    CHAM.visualizer = {
        init: function init(specifier, sel) {
            CHAM.VIZ = $(document.getElementById('visualizer'));
            //this.isIM = !!CHAM.DATA.specifier.includes('_USER_');
            loadingViz();
            CHAM.VIZ.ChamViz({
                spec: specifier,
                sel: sel,
                defaultSpec: specifier,
                defaultSel: sel,
                siteid: CHAM.SiteID,
                hightlightColor: CHAM.HighlightColor,
                responsiveMode: 'fit width',
                showMouseOvers: true,
                renderserver: CHAM.RenderServer,
                //renderFlags: '&force=0',
                surfacesLoaded: function (surfaceObjArr) {
                    CHAM.initialize.surfacesLoaded(surfaceObjArr);
                },
                AllSurfacesLoaded: function () {
                  CHAM.initialize.AllSurfacesLoaded();
                },
                initComplete: function () {
                    CHAM.initialize.initComplete();
                },
                applySurfaceHandler: function (img, surfaceObj, ChamVizContainer, productName, sel) {
                 
                  if (sel != '-1' && sel != '') {
                        LoadingMsg.startImg(img, 'Loading' + surfaceObj.sortorder, this, productName);
                        surfaceObj.sel = sel;
                    }
                },
                surfaceHighlighted: function (surface) {
                  if (!surface) return;
                    $('div.surface[data-surface="' + surface.name + '"]').addClass('hover');
              
                },
                surfaceUnHighlighted: function (surface) {
                  if (!surface) return;
                    $('div.surface[data-surface="' + surface.name + '"]').removeClass('hover');             
                },
                
              responsiveBreakpoints: [{ minWidth: 768, mode: 'fit width' },
                  { minWidth: 1025, mode: 'contain', alignment: 'center bottom' } 
                  ],
                
              chooseSurfaceHandler: function (surface) {
             
                var surfaceNumber = surface.specifiersurfacenumber;
                var type = _.lowerCase(surface.type);
                if (surface.type == 'Wall') type = 'brick';
                else if (surface.type != 'Wall' && surface.type != 'Roof') type = 'trim';

                CHAM.stores.events.resetSurfaces.emit(Number(surfaceNumber));
                var state = CHAM.store.getState();
                $('div.side-surface li').removeClass('active');
                $('div.side-surface ul[data-type="' + type + '"] li[data-surfacenumber="' + surfaceNumber + '"]').addClass('active');
                if (!$('div#menuProductTemplates').find('div[data-type="' + type + '"]').hasClass('active')) {
                  $('div#menuProductTemplates').children('div').removeClass();
                  $('div#menuProductTemplates').find('div[data-type="' + type + '"]').addClass('active');

                }
               
                $('div.surface-menu li[data-type="'+ type +'"]').toggleClass('active');
                $('div.surface-menu li[data-type="' + type + '"]').siblings().removeClass('active');
               
                  if (type === 'brick') {
                    if ($('div.mortar-products').hasClass('active')) {
                      if (state.BrickStone[state.surfacesSelected[0]] === '' || state.BrickStone[state.surfacesSelected[0]] === '-1') {
                        $('div.mortar-products li.mortar').addClass('hide');
                        $('div.mortar-products li.mortar-msg').addClass('active');
                      }
                      else {
                        $('div.mortar-products li.mortar[data-id="' + state.Mortar[state.surfacesSelected[0]] + '"]').addClass('active');
                        if ($('div.mortar-products li.mortar').hasClass('active')) {
                          $('div.mortar-products li.mortar[data-id="' + state.Mortar[state.surfacesSelected[0]] + '"]').get(0).scrollIntoView({ block: "nearest" });
                        }
                      }

                    }
                    else {
                      console.log("brickstone", state.BrickStone[state.surfacesSelected[0]]);
                      var sel = '';
                      $('div#brick-menu div.product-list li').removeClass('active');
                      $('div#brick-menu div.product-list li[data-id="' + state.BrickStone[state.surfacesSelected[0]] + '"]').addClass('active');
                      if ($('div#brick-menu div.product-list li').hasClass('active')) {

                        $('div#brick-menu div.product-list li[data-id="' + state.BrickStone[state.surfacesSelected[0]] + '"]').get(0).scrollIntoView({ block: "nearest" });
                      }

                    }
                  }
                  else if (type === 'trim') {
                    $('div#trim-menu div.trim-products li').removeClass('active');
                    $('div#trim-menu div.trim-products li[data-id="' + state.Trim[state.surfacesSelected[0]] + '"]').addClass('active');
                    if ($('div.trim-products li').hasClass('active')) {
                      $('div#trim-menu div.trim-products li[data-id="' + state.Trim[state.surfacesSelected[0]] + '"]').get(0).scrollIntoView({ block: "nearest" });
                    }

                  } else if (type === 'roof') {
                    $('div#roof-menu div.product-list li').removeClass('active');
                    $('div#roof-menu div.product-list li[data-id="' + state.Roof + '"]').addClass('active');
                    if ($('div#roof-menu div.product-list li').hasClass('active')) {
                      $('div#roof-menu div.product-list li[data-id="' + state.Roof + '"]').get(0).scrollIntoView({ block: "nearest" });
                    }
                  }
                }              
            });
        },
        applyFullSel: function applyFullSel(fullSel, displayName) {
            CHAM.VIZ.ChamViz({ action: 'unzoom' });
            var arr = Viz.sel.parseToSurfaceArray(fullSel);
            CHAM.VIZ.ChamViz({
                action: 'applyByFilter', sel: '', productName: displayName
              , fn: function (surface, newSel, currentSel) {
                var sel = arr[surface.sortorder - 1];
                  if (sel == void 0) return '-1';
                  else {
                    
                    return sel;
                  }
              }
            });
        },
        events: function events() {
          var root = $('div.side-surface');
          root.off('mouseover', 'li').on('mouseover', 'li', function () {
           
            if (!$('ul#trimSurfaceMenu li').hasClass('no-hover')) {
              $('div.ChamViz img.viz-highlight[data-surface="' + $(this).data('surfacenumber') + '"]').css('display', 'block');
            }
            if (!$('ul#brickSurfaceMenu li').hasClass('no-hover')) {
              $('div.ChamViz img.viz-highlight[data-surface="' + $(this).data('surfacenumber') + '"]').css('display', 'block');
            }
            
          });
          root.off('mouseleave', 'li').on('mouseleave', 'li', function () {
            if (!$('ul#trimSurfaceMenu li').hasClass('no-hover') ) {
              $('div.ChamViz img.viz-highlight[data-surface="' + $(this).data('surfacenumber') + '"]').css('display', 'none');
            }
            if (!$('ul#brickSurfaceMenu li').hasClass('no-hover')) {
              $('div.ChamViz img.viz-highlight[data-surface="' + $(this).data('surfacenumber') + '"]').css('display', 'none');
            }
          });
        }
    };

})();
