//TODO: zoom

//Requires: RoomsceneJSON.ashx, RenderProxy.ashx, jquery/1.9.1, jqueryui/1.10.2

/* USAGE:
<style type="text/css">
#myViz2 div.zoom-control {top:5px; left:5px;}
</style>

<div id="myViz" style="width:400px;"></div>


*/




(function () {
    try {
        var a = new Uint8Array(1);
        return; //no need
    } catch (e) { }

    function subarray(start, end) {
        return this.slice(start, end);
    }

    function set_(array, offset) {
        if (arguments.length < 2) offset = 0;
        for (var i = 0, n = array.length; i < n; ++i, ++offset)
            this[offset] = array[i] & 0xFF;
    }

    // we need typed arrays
    function TypedArray(arg1) {
        var result;
        if (typeof arg1 === "number") {
            result = new Array(arg1);
            for (var i = 0; i < arg1; ++i)
                result[i] = 0;
        } else
            result = arg1.slice(0);
        result.subarray = subarray;
        result.buffer = result;
        result.byteLength = result.length;
        result.set = set_;
        if (typeof arg1 === "object" && arg1.buffer)
            result.buffer = arg1.buffer;

        return result;
    }

    window.Uint8Array = TypedArray;
    window.Uint32Array = TypedArray;
    window.Int32Array = TypedArray;
})();


(function () {
    if ("response" in XMLHttpRequest.prototype ||
        "mozResponseArrayBuffer" in XMLHttpRequest.prototype ||
        "mozResponse" in XMLHttpRequest.prototype ||
        "responseArrayBuffer" in XMLHttpRequest.prototype)
        return;
    if (typeof Object.defineProperty == 'undefined') return;
    Object.defineProperty(XMLHttpRequest.prototype, "response", {
        get: function () {
            return new Uint8Array(new VBArray(this.responseBody).toArray());
        }
    });
})();

(function () {
    if ("btoa" in window)
        return;

    var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    window.btoa = function (chars) {
        var buffer = "";
        var i, n;
        for (i = 0, n = chars.length; i < n; i += 3) {
            var b1 = chars.charCodeAt(i) & 0xFF;
            var b2 = chars.charCodeAt(i + 1) & 0xFF;
            var b3 = chars.charCodeAt(i + 2) & 0xFF;
            var d1 = b1 >> 2, d2 = ((b1 & 3) << 4) | (b2 >> 4);
            var d3 = i + 1 < n ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
            var d4 = i + 2 < n ? (b3 & 0x3F) : 64;
            buffer += digits.charAt(d1) + digits.charAt(d2) + digits.charAt(d3) + digits.charAt(d4);
        }
        return buffer;
    };
})();



jQuery.fn.imgmover = function (options) {
    var $ = jQuery;
    return this.each(function () { //return jQuery obj
        if (this.tagName != "DIV")
            return true; //skip to next matched element
        var $imgref = $(this);
        ddimagepanner.init($, $imgref, options);
    });
};


var ddimagepanner = {
    maxzoom: 4, //set maximum zoom level (from 1x)

    init: function ($, $img, options) {
        var s = options;
        s.imagesize = [$img.width(), $img.height()];
        s.oimagesize = [$img.width(), $img.height()]; //always remember image's original size
        s.wrappersize = [options.$pancontainer.width(), options.$pancontainer.height()];

        s.pos = (s.pos == "center") ? [-(s.imagesize[0] / 2 - s.wrappersize[0] / 2), -(s.imagesize[1] / 2 - s.wrappersize[1] / 2)] : [0, 0]; //initial coords of image
        s.pos = [Math.floor(s.pos[0]), Math.floor(s.pos[1])];
      $img.css({ position: 'absolute', left: s.pos[0], top: s.pos[1]});
      $img.find('img').css({ objectFit: "fill", maxHeight: "none" });
        this.dragimage($, $img, s);
    },

    destroy: function ($img) {
        $img.off('mousedown touchstart');
        $(document).off('mouseup.zoompan touchend.zoompan');
        $(window).off('resize.visualizerzoom');
    },

    dragimage: function ($, $img, s) {
        $img.on('mousedown touchstart', function (e) {
            var changedTouches = e.originalEvent.changedTouches;
            var coordObject = changedTouches != null ? changedTouches[0] : e;
            var xypos = [coordObject.clientX, coordObject.clientY];
            var fudgePixels = +2; //force to double

            /* The fudge pixels are to help with responsive. Otherwise, when the container and/or image dimensions involve subpixels a small transparent band can appear on the bottom or right of the image.
               The jQuery width and height functions only return integers. An alternative is to use the DOM function getBoundingClientRect however even using this the issue still occurred.
               This is due to how browsers round subpixels when rendering, which varies between browsers. */

          s.pos = [parseInt($img.css('left')), parseInt($img.css('top'))];
          s.dragcheck = { h: (s.wrappersize[0] > s.imagesize[0]) ? false : true, v: (s.wrappersize[1] > s.imagesize[1]) ? false : true }

            $img.on('mousemove.dragstart touchmove.dragstart', function (e) {
                var changedTouches = e.originalEvent.changedTouches;
                var coordObject = changedTouches != null ? changedTouches[0] : e;

                var pos = s.pos, imagesize = s.imagesize, wrappersize = s.wrappersize;
                var dx = coordObject.clientX - xypos[0]; //distance to move horizontally
                var dy = coordObject.clientY - xypos[1]; //vertically
                //s.dragcheck = { h: (wrappersize[0] > imagesize[0]) ? false : true, v: (wrappersize[1] > imagesize[1]) ? false : true }

                if (s.dragcheck.h == true) //allow dragging horizontally?
                    var newx = (dx > 0) ? Math.min(0, pos[0] + dx) : Math.max(-imagesize[0] + wrappersize[0] + fudgePixels, pos[0] + dx); //Set horizonal bonds. dx>0 indicates drag right versus left
                if (s.dragcheck.v == true) //allow dragging vertically?
                    var newy = (dy > 0) ? Math.min(0, s.pos[1] + dy) : Math.max(-imagesize[1] + wrappersize[1] + fudgePixels, pos[1] + dy); //Set vertical bonds. dy>0 indicates drag downwards versus up

              $img.css({ left: (typeof newx != "undefined") ? newx : pos[0], top: (typeof newy != "undefined") ? newy : pos[1] });



                return false; //cancel default drag action
            });

            return false; //cancel default drag action
        });

        $(document).on('mouseup.zoompan touchend.zoompan', function () {
            $img.off('mousemove.dragstart touchmove.dragstart');
        });

        $(window).on('resize.visualizerzoom', function () {
            var wrappersize = [s.$pancontainer.width(), s.$pancontainer.height()];
            if (s.wrappersize[0] === wrappersize[0] && s.wrappersize[1] === wrappersize[1])
                return;

            s.wrappersize = wrappersize;
            s.pos = [parseInt($img.css('left')), parseInt($img.css('top'))];

            var emptySpaceRight = Math.max(s.wrappersize[0] - s.pos[0] - s.imagesize[0], 0);
            var emptySpaceTop = Math.max(s.wrappersize[1] - s.pos[1] - s.imagesize[1], 0);
            if (emptySpaceRight === 0 && emptySpaceTop === 0)
                return;

            var leftShift = s.pos[0] + emptySpaceRight;
            var topShift = s.pos[1] + emptySpaceTop;
            if (leftShift <= 0 || topShift <= 0)
                $img.css({ left: Math.min(leftShift, 0), top: Math.min(topShift, 0) });
        });
    }
};


(function ($) {
    $.fn.extend({
        //pass the options variable to the function
        ChamViz: function (options, sceneData) {
            var internal = {};
            internal.test = function () {
                var _opt = [];
                this.each(function () {
                    _opt = jQuery.data(this, "options");
                });
                return _opt;
            };

            var tImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAA1JREFUGFdj+P//PwMACPwC/ohfBuAAAAAASUVORK5CYII=';

            internal.apply = function (options) {  //surfaceNum, sel, productName

                this.each(function () {
                    $(this).trigger('apply', [options]);
                    var storedOptions = jQuery.data(this, "options");
                    var img = $(this).find('img.viz-layer').eq(options.surface - 1);


                    var surfaceNum = options.surface, sel = options.sel, productName = options.productName || '';
                    var currentSel = img.attr('data-sel');
                    if (currentSel == sel) return;


                    //setup undo and execute
                    var renderserver = storedOptions.renderserver;
                    var sessionId = storedOptions.sessionId;
                    var siteid = storedOptions.siteid;
                    var currentproductName = img.attr('data-productname');
                    var renderFlags = storedOptions.renderFlags;
                    var baseImageRenderMode = storedOptions.baseImageRenderMode;

                    var currentFullSel = $(this).ChamViz({ action: 'getFullSelStringAsArray' });
                    var newFullSel = currentFullSel.slice(0); //clone

                    //if this is an auxilarly surface wwe may need to clear all simular aux surfaces
                    var parent = img.parent();
                    var auxtype = img.attr('data-type');
                    var baseSpec = parent.find('img.viz-layer[data-surface="1"]').attr('data-specifier');
                    var specifier = img.attr('data-specifier');
                    if (specifier != baseSpec) {
                        parent.find('img[data-type="' + auxtype + '"]').each(function () {
                            var surf = this.getAttribute('data-surface');
                            newFullSel[parseInt(surf) - 1] = '-1';
                        });
                    }

                    newFullSel[parseInt(surfaceNum) - 1] = sel;
                    var _this = this;
                    jQuery.data(this, "history").push({ 'full': newFullSel.join(','), 'sel': sel, 'surface': storedOptions.surfaces[surfaceNum - 1 || 0] });

                    jQuery.data(this, "undoQ").execute({
                        execute: function () {
                            var width = storedOptions.width;
                            internal._setLayer(img, newFullSel, width, siteid, productName, renderserver, sessionId, renderFlags, baseImageRenderMode, storedOptions.srcBuilderHandler, _this);
                        },
                        unexecute: function () {
                            var width = storedOptions.width;
                            internal._setLayer(img, currentFullSel, width, siteid, currentproductName, renderserver, sessionId, renderFlags, baseImageRenderMode, storedOptions.srcBuilderHandler, _this);
                        }
                    });
                    storedOptions.applySurfaceHandler.call(this, img, storedOptions.surfaces[parseInt(options.surface - 1)], this, productName, sel);


                });
            };

            //pass sel, productName,type
            internal.applyByType = function (options) {
                this.each(function () {
                    $(this).trigger('apply', [options]);
                    var storedOptions = jQuery.data(this, "options");
                    var sel = options.sel;
                    var renderserver = storedOptions.renderserver;
                    var sessionId = storedOptions.sessionId;
                    var siteid = storedOptions.siteid;
                    var productName = options.productName || '';
                    var renderFlags = storedOptions.renderFlags;
                    var tmp = this;
                    var baseImageRenderMode = storedOptions.baseImageRenderMode;

                    var currentFullSel = $(this).ChamViz({ action: 'getFullSelStringAsArray' });
                    var newFullSel = currentFullSel.slice(0); //clone

                    var tmp2 = null;
                    var currentSurface = null, currentproductName = null;
                    $(this).find('img.viz-layer[data-type="' + options.type + '"]').each(function () {
                        tmp2 = $(this);
                        var currentSel = ($(this).attr('data-sel'));
                        currentproductName = ($(this).attr('data-productname')) || '';
                        currentSurface = ($(this).attr('data-surface'));
                        storedOptions.applySurfaceHandler.call(tmp, $(this), storedOptions.surfaces[parseInt((currentSurface) - 1)], tmp, productName, sel);

                        newFullSel[parseInt(currentSurface) - 1] = sel;

                    });

                    jQuery.data(this, "history").push({ 'full': newFullSel.join(','), 'sel': sel, 'surface': storedOptions.surfaces[currentSurface - 1 || 0] });
                    var _this = this;
                    if (tmp2 != null) {
                        jQuery.data(this, "undoQ").execute({
                            execute: function () {
                                var width = storedOptions.width;
                                internal._setLayer(tmp2, newFullSel, width, siteid, productName, renderserver, sessionId, renderFlags, baseImageRenderMode, storedOptions.srcBuilderHandler, _this);
                            },
                            unexecute: function () {
                                var width = storedOptions.width;
                                internal._setLayer(tmp2, currentFullSel, width, siteid, currentproductName || '', renderserver, sessionId, renderFlags, baseImageRenderMode, storedOptions.srcBuilderHandler, _this);
                            }
                        });
                    }

                });
            };



            //pass sel, productName, fn = function(surface,newSel, currentSel){ returns finalSel;}
            // fn return a new sel if there is a match or the currentSel if not.
            //inside fn: this = the img.viz-layer that is being proccessed
            internal.applyByFilter = function (options) {
                this.each(function () {
                    $(this).trigger('apply', [options]);
                    var storedOptions = jQuery.data(this, "options");
                    var sel = options.sel;
                    var renderserver = storedOptions.renderserver;
                    var sessionId = storedOptions.sessionId;
                    var siteid = storedOptions.siteid;
                    var productName = options.productName || '';
                    var renderFlags = storedOptions.renderFlags;
                    var tmp = this;
                    var baseImageRenderMode = storedOptions.baseImageRenderMode;


                    var currentFullSel = $(this).ChamViz({ action: 'getFullSelStringAsArray' });
                    var newFullSel = currentFullSel.slice(0); //clone
                    var currentproductName = '';
                    var currentSurface = null;
                    var tmp2 = null;

                    var i = -1, len = storedOptions.surfaces.length, firstSurface = true;
                    while (++i < len) {
                        tmp2 = $(this).find('img.viz-layer[data-surface="' + (i + 1) + '"]');
                        var __sel = options.fn.call(tmp2, storedOptions.surfaces[i], sel, currentFullSel[i]);
                        if (__sel != currentFullSel[i]) {
                            currentSurface = i + 1;
                            currentproductName = (tmp2.attr('data-productname') || '');
                            if (firstSurface) storedOptions.applySurfaceHandler.call(tmp, tmp2, storedOptions.surfaces[i], tmp, productName, __sel);
                            firstSurface = false;
                        }
                        newFullSel[i] = __sel;
                    }


                    jQuery.data(this, "history").push({ 'full': newFullSel.join(','), 'sel': sel, 'surface': storedOptions.surfaces[currentSurface - 1 || 0] });
                    var _this = this;
                    if (tmp2 != null) {
                        jQuery.data(this, "undoQ").execute({
                            execute: function () {
                                var width = storedOptions.width;
                                internal._setLayer(tmp2, newFullSel, width, siteid, productName, renderserver, sessionId, renderFlags, baseImageRenderMode, storedOptions.srcBuilderHandler, _this);
                            },
                            unexecute: function () {
                                var width = storedOptions.width;
                                internal._setLayer(tmp2, currentFullSel, width, siteid, currentproductName, renderserver, sessionId, renderFlags, baseImageRenderMode, storedOptions.srcBuilderHandler, _this);
                            }
                        });
                    }

                });
            };


            internal._setLayer = function (img, fullSelArray, width, siteid, productName, renderserver, sessionId, renderFlags, baseImageRenderMode, srcBuilderFn, _this) {
                baseImageRenderMode = baseImageRenderMode || false;
                var parent = img.parent();
                var arr = fullSelArray;
                var imgSurfaceNum = img.attr('data-surface');
                var i = 0, len = arr.length;
                while (++i <= len) {
                    var sel = arr[i - 1];
                    if (sel == '' || sel == void 0) sel = '-1';
                    var currentLayer = parent.find('img.viz-layer[data-surface="' + i + '"]');
                    var currentSel = currentLayer.attr('data-sel');
                    if (currentSel == '') currentSel = '-1';
                    var currentRotation = currentLayer.attr('data-rotate');
                    var rotationApply = '()';
                    if (currentRotation != void 0) {
                        rotationApply = '?,'.repeat(parseInt(imgSurfaceNum) - 1);
                        rotationApply += '(XFORM=ROTATE(' + currentRotation + '))';
                        rotationApply = '(SURFACE=(' + rotationApply + '))';
                    }
                    if (sel != currentSel) {
                        var src = tImg;
                        if (sel != '-1') {
                            var useSelString = true;
                            if (jQuery.data(_this, "interned") != void 0) {
                                var apply = internal._fullSellToApply(Array(parseInt(currentLayer.attr('data-specifiersurfacenumber'))).join("-1,") + sel, jQuery.data(_this, "interned"));
                                if (apply != null) {
                                    useSelString = false;
                                    src = renderserver + 'GetImage.ashx?Specifier=' + encodeURIComponent(currentLayer.attr('data-specifier')) + rotationApply;
                                    src += encodeURIComponent(apply);
                                    src += '&Type=Layer&Size=' + width + '&SiteID=' + siteid + '&SurfaceNum=' + currentLayer.attr('data-specifiersurfacenumber');
                                    src += renderFlags;
                                    src += '&r=' + Math.floor(Math.random() * 1100); //force browser to see this as new
                                    src += '&session=' + sessionId;
                                }
                            }
                            //_fullSellToApply

                            if (useSelString) {
                                src = renderserver + 'GetImage.ashx?Specifier=' + encodeURIComponent(currentLayer.attr('data-specifier')) + rotationApply + '&Type=Layer&Size=' + width + '&SiteID=' + siteid + '&Sel=' + sel + '&SurfaceNum=' + currentLayer.attr('data-specifiersurfacenumber');
                                src += renderFlags;
                                src += '&r=' + Math.floor(Math.random() * 1100); //force browser to see this as new
                                src += '&session=' + sessionId;
                            }
                        }

                        currentLayer.attr('data-old-src', currentLayer.attr('src'));
                        currentLayer.attr('data-sel', sel);
                        if (imgSurfaceNum == i) {
                            currentLayer.attr('data-productname', productName);
                        }

                        if (!baseImageRenderMode) {
                            if (srcBuilderFn != void 0) src = srcBuilderFn.call(this, src, width, siteid, productName, renderserver, sessionId, renderFlags, currentLayer.attr('data-specifier'), sel, currentLayer.attr('data-specifiersurfacenumber'));
                            currentLayer.attr('src', src);
                            var q = jQuery.data(_this, "undoQ");
                            window.setTimeout(function (sel) {
                                $(_this).trigger("undoQ", { "canUndo": q.executed.length > 0, "canRedo": q.unexecuted.length > 0 });
                                if (window.ChamStats != void 0) window.ChamStats.sendData('apply', sel);
                            }.bind(null, sel), 1);
                        }
                        else {
                            if (window.ChamStats != void 0 && sel != void 0) window.ChamStats.sendData('apply', sel);
                        }
                    }
                }


                var doneInterval = window.setInterval(
                            function () {
                                var done = true;
                                var els = parent.find('img.viz-layer');
                                if (els.length == 0) return;
                                els.each(function () {
                                    if (this.complete != true) done = false;
                                });
                                if (done) cleardoneInterval();
                            }, 200);

                var cleardoneInterval = function () { window.clearInterval(doneInterval); var options = jQuery.data(_this, "options"); options.AllSurfacesLoaded.call(_this); };

                if (baseImageRenderMode) {
                    var mysel = '&sel=' + fullSelArray.join(',');
                    var baseImg = parent.find('img.viz-baseimage');
                    baseImg.bind('load abort error', function (e) {
                        parent.find('img.viz-layer').attr('src', tImg).on('load');
                        $(this).unbind(e);
                    });

                    var _src = baseImg.attr('src')
                    if (_src.indexOf('&sel=') > -1) baseImg.attr('src', _src.replace(/&sel=[^&]+/, mysel));
                    else baseImg.attr('src', _src + mysel);
                    var q = jQuery.data(_this, "undoQ");
                    window.setTimeout(function () {
                        $(_this).trigger("undoQ", { "canUndo": q.executed.length > 0, "canRedo": q.unexecuted.length > 0 });
                    }, 1);
                }
            };

            internal.refreshAll = function (options) {
                this.each(function () {
                    var storedOptions = jQuery.data(this, "options");
                    var renderserver = storedOptions.renderserver;
                    var sessionId = storedOptions.sessionId;
                    var siteid = storedOptions.siteid;
                    var width = storedOptions.width;
                    var renderFlags = storedOptions.renderFlags;
                    var parent = $(this);
                    var i = 0, len = storedOptions.surfaces.length + 1, firstSurface = true, src = '';



                    while (++i < len) {
                        var currentLayer = parent.find('img.viz-layer[data-surface="' + i + '"]');
                        var currentSel = currentLayer.attr('data-sel');
                        var currentRotation = currentLayer.attr('data-rotate');
                        var rotationApply = '()';
                        if (currentRotation != void 0) {
                            rotationApply = '?,'.repeat(parseInt(i) - 1);
                            rotationApply += '(XFORM=ROTATE(' + currentRotation + '))';
                            rotationApply = '(SURFACE=(' + rotationApply + '))';
                        }
                        if (currentSel != '-1') {
                            src = renderserver + 'GetImage.ashx?Specifier=' + encodeURIComponent(currentLayer.attr('data-specifier')) + rotationApply + '&Type=Layer&Size=' + width + '&SiteID=' + siteid + '&Sel=' + currentSel + '&SurfaceNum=' + currentLayer.attr('data-specifiersurfacenumber');
                            src += renderFlags;
                            src += '&r=' + Math.floor(Math.random() * 1100); //force browser to see this as new
                            src += '&session=' + sessionId;
                            currentLayer.attr('src', src);
                        }
                    }
                });
            };

            internal.rotateSurface = function (options) {
                var _this = this;
                _this.each(function () {
                    var storedOptions = jQuery.data(this, "options");
                    var img = $(this).find('img.viz-layer').eq(options.surface - 1);
                    var newRotation = String(options.rotate).replace('+', '').replace('-', '');;
                    var current = img.attr('data-rotate');
                    
                    if (current != void 0 && current != '') {
                        if (String(options.rotate).indexOf('+') > -1) newRotation = parseInt(current) + parseInt(newRotation);
                        else if (String(options.rotate).indexOf('-') > -1) newRotation = parseInt(current) - parseInt(newRotation);
                    }
                    if (Math.abs(parseInt(newRotation)) > 360) newRotation = 0;
                    img.attr('data-rotate', newRotation);
                    internal.refreshAll.call(_this, {});
                });
            };


            internal.setAllBySeries = function (options) {
                //TODO : use filter
            };

            internal.getSaveData = function (options) {
                var out = {};
                this.each(function () {
                    var url = [location.protocol, '//', location.host, location.pathname].join('');
                    var storedOptions = jQuery.data(this, "options");
                    out.spec = storedOptions.spec;
                    out.sel = $(this).ChamViz({ action: 'getFullSelString' });
                    out.url = url + "?specifier=" + encodeURIComponent(storedOptions.spec) + "&Sel=" + encodeURIComponent(out.sel);
                    out.render = storedOptions.renderserver + 'getImage.ashx?type=base&spec=' + encodeURIComponent(storedOptions.spec) + '&sel=' + encodeURIComponent(out.sel);
                    out.render += storedOptions.renderFlags;
                    var fblink = storedOptions.spec.replace(/\\/ig, '.');
                    fblink += 'seln' + out.sel;
                    out.fblink = fblink;
                });
                return out;
            };

            internal.resetRenderFlags = function (options) {
                this.each(function () {
                    var storedOptions = jQuery.data(this, "options");
                    $(this).find('img.viz-layer').each(function () {
                        var src = this.getAttribute('src');
                        if (storedOptions.renderFlags != '') src = src.replace(storedOptions.renderFlags, options.renderFlags);
                        else src += options.renderFlags;
                        this.setAttribute('src', src);
                    });
                    storedOptions.renderFlags = options.renderFlags;
                });
            };

            internal.getLayer = function (options) {
                var layer;
                this.each(function () {
                    layer = $(this).find('img.viz-layer').eq(options.surface - 1);
                });
                return layer;
            };
            internal.createGUID = function () {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            };
            internal.getFullSelString = function (options) {
                var FullSelString;
                this.each(function () {
                    var arr = $(this).find('img.viz-layer').toArray();
                    var out = '', comma = '';
                    var isEmpty = function (sel) {
                        if (sel == null) return true;
                        sel = String(sel).replace(/^\s*|\s*$/g, '');
                        return sel.length < 1
                    }
                    for (var i = 0; arr[i]; ++i) {
                        var s = arr[i].getAttribute('data-sel');
                        if (isEmpty(s)) s = '-1';
                        out += comma + s;
                        comma = ',';
                    }
                    FullSelString = out;
                });
                return FullSelString;
            };
            internal.getFullSelStringAsArray = function (options) {
                var FullSelString = [];
                this.each(function () {
                    var arr = $(this).find('img.viz-layer').toArray();
                    var out = [];
                    var isEmpty = function (sel) {
                        if (sel == null) return true;
                        sel = String(sel).replace(/^\s*|\s*$/g, '');
                        return sel.length < 1
                    }
                    for (var i = 0; arr[i]; i++) {
                        var s = arr[i].getAttribute('data-sel');
                        if (isEmpty(s)) s = '-1';
                        out.push(s);
                    }
                    FullSelString = out;
                });
                return FullSelString;
            };

            internal.clearAll = function (options) {
                this.each(function () {
                    $(this).trigger('clearAll', [options]);
                    var arr = $(this).find('img.viz-layer').toArray();
                    var isEmpty = function (sel) {
                        if (sel == null) return true;
                        sel = String(sel).replace(/^\s*|\s*$/g, '');
                        return sel.length < 1
                    }
                    var storedOptions = jQuery.data(this, "options");
                    var renderserver = storedOptions.renderserver;
                    var sessionId = storedOptions.sessionId;
                    var siteid = storedOptions.siteid;
                    var renderFlags = storedOptions.renderFlags;
                    var tmp = $(arr[0]);
                    var landingSels = jQuery.data(this, "history")[0].full.split(',');
                    var currentFullSel = $(this).ChamViz({ action: 'getFullSelStringAsArray' });
                    var newFullSel = currentFullSel.slice(0); //clone
                    var baseImageRenderMode = storedOptions.baseImageRenderMode;

                    for (var i = 0; newFullSel[i]; ++i) {
                        if (landingSels.length == 1 && landingSels[0] == '-1') newFullSel[i] = '-1';
                        else if (landingSels[i] === undefined) newFullSel[i] = '-1';
                        else newFullSel[i] = landingSels[i];
                    }


                    jQuery.data(this, "history").push({ 'full': newFullSel.join(','), 'sel': '-1', 'surface': storedOptions.surfaces[0] });
                    var _this = this;
                    jQuery.data(this, "undoQ").execute({
                        execute: function () {
                            var width = storedOptions.width;
                            internal._setLayer(tmp, newFullSel, width, siteid, '', renderserver, sessionId, renderFlags, baseImageRenderMode, null, _this);
                        },
                        unexecute: function () {
                            var width = storedOptions.width;
                            internal._setLayer(tmp, currentFullSel, width, siteid, '', renderserver, sessionId, renderFlags, baseImageRenderMode, null, _this);
                        }
                    });
                    storedOptions.clearAllHandler.call(this);
                });
            };

            internal.clear = function (options) {
                this.each(function () {
                    $(this).trigger('clear', [options]);
                    var sNum = options.surface || 0;
                    var arr = $(this).find('img.viz-layer').toArray();
                    var isEmpty = function (sel) {
                        if (sel == null) return true;
                        sel = String(sel).replace(/^\s*|\s*$/g, '');
                        return sel.length < 1
                    }
                    var storedOptions = jQuery.data(this, "options");
                    var renderserver = storedOptions.renderserver;
                    var sessionId = storedOptions.sessionId;
                    var siteid = storedOptions.siteid;
                    var renderFlags = storedOptions.renderFlags;
                    var tmp = $(arr[0]);
                    var landingSels = jQuery.data(this, "history")[0].full.split(',');
                    var currentFullSel = $(this).ChamViz({ action: 'getFullSelStringAsArray' });
                    var newFullSel = currentFullSel.slice(0); //clone
                    var baseImageRenderMode = storedOptions.baseImageRenderMode;

                    for (var i = 0; newFullSel[i]; ++i) {
                        if (i == parseInt(sNum - 1)) {
                            if (landingSels.length == 1 && landingSels[0] == '-1') newFullSel[i] = '-1';
                            else if (landingSels[i] === undefined) newFullSel[i] = '-1';
                            else newFullSel[i] = landingSels[i];
                        }
                        else {
                            newFullSel[i] = currentFullSel[i];
                        }
                    }

                    var _this = this;

                    jQuery.data(this, "history").push({ 'full': newFullSel.join(','), 'sel': '-1', 'surface': storedOptions.surfaces[0] });

                    jQuery.data(this, "undoQ").execute({
                        execute: function () {
                            var width = storedOptions.width;
                            internal._setLayer(tmp, newFullSel, width, siteid, '', renderserver, sessionId, renderFlags, baseImageRenderMode, null, _this);
                        },
                        unexecute: function () {
                            var width = storedOptions.width;
                            internal._setLayer(tmp, currentFullSel, width, siteid, '', renderserver, sessionId, renderFlags, baseImageRenderMode, null, _this);
                        }

                    });
                    storedOptions.clearAllHandler.call(this);
                });
            };

            internal.undo = function (options) {
                this.each(function () {
                    var q = jQuery.data(this, "undoQ");
                    q.undo();
                    //$(this).trigger("undoQ", { "canUndo": q.executed.length > 0, "canRedo": q.unexecuted.length > 0 });
                });
            };

            internal.redo = function (options) {
                this.each(function () {
                    var q = jQuery.data(this, "undoQ");
                    q.redo();
                    //$(this).trigger("undoQ", { "canUndo": q.executed.length > 0, "canRedo": q.unexecuted.length > 0 });
                });
            };
            internal.undoReset = function (options) {
                this.each(function () {
                    var q = jQuery.data(this, "undoQ");
                    if (q) {
                        q.reset();
                        $(this).trigger("undoQ", { "canUndo": q.executed.length > 0, "canRedo": q.unexecuted.length > 0 });
                    }
                });
            };

            internal.getFrozenLayerData = function (options) {
                var surfaceInfo = {};
                this.each(function () {
                    var storedOptions = jQuery.data(this, "options");
                    if ($(this).parent().find('div.viz-control').hasClass('frozen')) {
                        var getFrozenData = $(this).parent().find('div.viz-control').data('frozen');
                        if (typeof getFrozenData != 'undefined') {
                            var s = getFrozenData.sortorder;
                            surfaceInfo = $(this).parent().find('img.viz-layer').eq(s - 1).data();
                        }
                    }
                });
                return surfaceInfo;
            };

            internal.freeze = function (options) {
                this.each(function () {
                    $(this).trigger('freeze', [options]);
                    var storedOptions = jQuery.data(this, "options");
                    var s = options.showHighlightLayer;
                    $(this).parent().find('div.viz-control').data('frozen', { sortorder: parseInt(s) }).addClass('frozen');
                    if (typeof s != 'undefined') {
                        $(this).parent().find('img.viz-highlight').eq(s - 1).css('display', 'block');
                    }
                });
            };

            internal.unfreeze = function (options) {
                this.each(function () {
                    $(this).trigger('unfreeze', [options]);
                    var storedOptions = jQuery.data(this, "options");
                    $(this).parent().find('div.viz-control').removeClass('frozen');
                    $(this).parent().find('img.viz-highlight').css('display', 'none');
                    $(this).parent().find('div.viz-control').data('frozen', {});
                });
            };



            internal.history = function (options) {
                var o = [];
                this.each(function () {
                    o = jQuery.data(this, "history");
                });
                return o.slice(0); //clone
            };

            internal.getOptions = function (options) {
                var o = {};
                this.each(function () {
                    o = jQuery.data(this, "options");
                });
                return o; //clone
            };
            internal.extendOptions = function (options) {
                var o = {};
                this.each(function () {
                    o = jQuery.data(this, "options");
                    $.extend(o, options);
                });
                return o; //clone
            };

            internal.getMouseOverCoords = function (options) {
                var a = {};
                this.each(function () {
                    a = jQuery.data(this, "options").areamaps;
                });
                return a; //clone
            };


            internal.getSurfaceDataArray = function (options) {
                var surfaces = [];
                this.each(function () {
                    var storedOptions = jQuery.data(this, "options");
                    surfaces = storedOptions.surfaces;
                });
                return surfaces;
            };


            internal.zoom = function (options) {
                this.each(function () {
                    $(this).trigger('zoom', [options]);
                    var storedOptions = jQuery.data(this, "options");
                    var img = document.createElement('img');
                    $(img).css({ position: 'absolute', left: 0, top: 0, 'z-index': 0 });
                    var zoomDiv = $(this).find('div.ZoomLayer');
                    if (zoomDiv.find('img').length > 0) return;
                    zoomDiv.find('div:eq(0)').append(img);
                    var baseImg = $(this).find('img.viz-baseimage');
                    var zoomWidth = storedOptions.zoomWidth;//parseInt(storedOptions.width * 2);
                    var src = baseImg.attr('src').replace(/size=\d+/i, 'size=' + zoomWidth);

                    if (storedOptions.isauxiliarysurfaces)
                        src = src.replace(/GetImage.ashx/i, 'GetImageWithAuxSurfaces.ashx');
                    src += '&sel=' + $(this).ChamViz({ action: 'getFullSelString' });  //internal.getFullSelString();  //Viz.Sel.getAll();
                    src += '&r=' + Math.floor(Math.random() * 1100);
                  if (storedOptions.srcBuilderHandler != void 0) src = storedOptions.srcBuilderHandler.call(this, src, zoomWidth, storedOptions.siteid, "", storedOptions.renderserver, storedOptions.sessionId, storedOptions.renderFlags, storedOptions.spec, $(this).ChamViz({ action: 'getFullSelString' }), 0);

                    LoadingMsg.startImg(img, 'zoom', this, storedOptions.LoadZoomMessage);
                    $(img).attr({ 'src': src });
                    //o.finnishedSizingCallback(o.finnishedSizingCallbackData);
                    var bi = $(this);
                    var zoomDivDimen = storedOptions.isResponsive
			            ? { width: '100%', height: '100%' }
			            : { width: bi.width() + 'px', height: bi.height() + 'px' };

                    zoomDiv.css({ cursor: 'move', width: zoomDivDimen.width, height: zoomDivDimen.height });
                    // bridgeline crane might have a better way of doing the above.

                    //var $img = $this.find('div:eq(0)'); //image to pan

                    zoomDiv.show(); //do not use jquery effects here
                    zoomDiv.find('div:eq(0)').css({ width: zoomWidth + 'px', height: ((zoomWidth / baseImg.width()) * baseImg.height()) + 'px' });

                    var options = { $pancontainer: zoomDiv, pos: zoomDiv.attr('data-orient'), curzoom: 1, canzoom: zoomDiv.attr('data-canzoom') };
                    zoomDiv.find('div:eq(0)').imgmover(options);
                });
            };

            internal.unzoom = function (options) {
                this.each(function () {
                    $(this).trigger('unzoom', [options]);
                    var z = $(this).find('div.ZoomLayer');
                    z.hide("scale", function () {
                        z.find('div:eq(0)').empty();
                    }, 500);  //requires jqueryui/1.10.2
                    ddimagepanner.destroy(z.find('div:eq(0)'));
                });
            };


            internal.pointZoom = function (options) {  //factor,levels
                this.each(function () {
                    $(this).trigger('zoom', [options]);
                    var _t = this;
                    var storedOptions = jQuery.data(this, "options");
                    var factor = parseFloat(options.factor), levels = parseInt(options.levels || 1);
                    var zoomDiv = $(this).find('div.ZoomLayer');

                    var reset = function () {
                        //unzoom
                        $(_t).trigger('unzoom');
                        zoomDiv.hide("scale", function () {
                            zoomDiv.find('div:eq(0)').empty();
                        }, 500);
                        zoomDiv.css({ cursor: 'default' });
                        var ctrl = $(_t).find('div.zoom-control');
                        ctrl.hide().off('click');
                        ctrl.data('factor', null);
                        ctrl.data('level', null);

                    }


                    var zoom = function zoom(factor, x, y) {
                        var img = document.createElement('img');
                        $(img).css({ position: 'absolute', left: 0, top: 0, 'z-index': 0 });
                        var zoomDiv = $(_t).find('div.ZoomLayer');
                        zoomDiv.find('div:eq(0)').append(img);
                        var baseImg = $(_t).find('img.viz-baseimage');
                        //var src = baseImg.attr('src');
                        var src = storedOptions.renderserver + 'GetImage.ashx?Specifier=' + encodeURIComponent(storedOptions.spec) + '()&Type=Base&Size=' + zoomDiv.width();
                        src += '&sel=' + $(_t).ChamViz({ action: 'getFullSelString' });  //internal.getFullSelString();  //Viz.Sel.getAll();
                        src += '&r=' + Math.floor(Math.random() * 1100);
                        if (storedOptions.renderFlags.indexOf('&extra=(') == -1) src += '&extra=(zoom=(FACTOR=' + factor.toString() + ',X=' + x.toString() + ',Y=' + y.toString() + '))';
                        else {
                            window.rf = storedOptions.renderFlags;
                            var __xtra = storedOptions.renderFlags.indexOf('&extra=(') + '&extra=('.length;
                            src += storedOptions.renderFlags.slice(0, __xtra) + 'zoom=(FACTOR=' + factor.toString() + ',X=' + x.toString() + ',Y=' + y.toString() + '),' + storedOptions.renderFlags.slice(__xtra);
                        }

                        LoadingMsg.stop('zoom');
                        LoadingMsg.startImg(img, 'zoom', _t, storedOptions.LoadZoomMessage);
                        $(img).attr({ 'src': src });
                        zoomDiv.show();
                    }


                    function showControlLayer() {
                        var ctrl = $(_t).find('div.zoom-control');
                        ctrl.show(); //do not use jquery effects here
                        ctrl.css({ cursor: 'zoom-in' }); //crosshair
                        if (ctrl.css('cursor') != 'zoom-in') ctrl.css({ cursor: 'crosshair' });
                        ctrl.html('<img style="border:none;" src="' + tImg + '" alt="" width="' + zoomDiv.width() + '" height="' + zoomDiv.height() + '" />');
                        ctrl.off('click').on('click', function (e) {
                            var _level = ctrl.data('level') || 0;
                            if (_level < levels) ctrl.data('level', ++_level);
                            else {
                                reset();
                                return;
                            }

                            var _factor = ctrl.data('factor');
                            var currFactor = _factor || 1;
                            if (_factor == void 0) _factor = factor;
                            else _factor *= factor;
                            ctrl.data('factor', _factor);

                            var w = $(this).parent().width(), h = $(this).parent().height();

                            if (_level > 1) {
                                var x = ctrl.data('zoomX');
                                var y = ctrl.data('zoomY');

                                var parentOffset = $(this).parent().offset();
                                var relX = e.pageX - parentOffset.left || 0;
                                var relY = e.pageY - parentOffset.top || 0;



                                var diffX = ((relX - (w / 2)) / currFactor);
                                var diffY = ((relY - (h / 2)) / currFactor);

                                ctrl.data('zoomX', x + diffX);
                                ctrl.data('zoomY', y + diffY);

                                x = Math.round(((x + diffX) / w) * 100) / 100;
                                y = Math.round(((y + diffY) / h) * 100) / 100;

                            }
                            else {
                                var parentOffset = $(this).parent().offset();
                                var relX = e.pageX - parentOffset.left || 0;
                                var relY = e.pageY - parentOffset.top || 0;
                                var x = Math.round((relX / w) * 100) / 100;
                                var y = Math.round((relY / h) * 100) / 100;

                                ctrl.data('zoomX', relX);
                                ctrl.data('zoomY', relY);
                            }
                            zoom(_factor, x, y);
                        });
                    }

                    if (parseInt(factor) <= 1) {
                        reset();
                        return;
                    }

                    showControlLayer();


                });
            };


            internal.getRoomSceneDims = function (options) {
                this.each(function () {
                    var storedOptions = jQuery.data(this, "options");
                    var maxWidth = options.MaxWidth || storedOptions.width;
                    var maxHeight = options.MaxHeight;
                    var getImg = new Image();
                    getImg.crossOrigin = "Anonymous";
                    var imgURL = storedOptions.renderserver + "GetImage.ashx?Specifier=" + storedOptions.spec + "&Type=Base&Size=" + maxWidth + "&SiteID=" + storedOptions.siteid;
                    var wDim = 0, hDim = 0, ratio = 0;

                    if (options.scale == 'fit') {
                        var calcFitImage = function calcFitImage(srcWidth, srcHeight, maxW, maxH) {
                            srcWidth = parseInt(srcWidth);
                            srcHeight = parseInt(srcHeight);
                            maxW = parseInt(maxW);
                            maxH = parseInt(maxH);
                            var wRatio = parseFloat(maxW / srcWidth);
                            var hRatio = parseFloat(maxHeight / srcHeight);
                            var nRatio = Math.min(wRatio, hRatio);
                            var nWidth = parseFloat(srcWidth * nRatio);
                            var nHeight = parseFloat(srcHeight * nRatio);
                            return { width: nWidth, height: nHeight };
                        };

                        getImg.onload = function () {
                            ratio = 0;
                            wDim = getImg.width;
                            hDim = getImg.height;
                            var nWidth = 0;
                            var nHeight = 0;

                            /*if (wDim > maxWidth) {
								ratio = maxWidth / wDim;   // get ratio for scaling image
								nWidth = hDim * ratio;    // Reset width to match scaled image
								nHeight = hDim * ratio;    // Reset height to match scaled image
							}

							// Check if current height is larger than max
							if (hDim > maxHeight) {
								ratio = maxHeight / hDim; // get ratio for scaling image
								nWidth = wDim * ratio;    // Reset width to match scaled image
								nHeight = hDim * ratio;    // Reset height to match scaled image
							}*/

                            var getNewSizes = calcFitImage(wDim, hDim, maxWidth, maxHeight);
                            nWidth = Math.floor(getNewSizes.width);
                            nHeight = Math.floor(getNewSizes.height);

                            storedOptions.GetRoomSceneDimensions.call(null, nWidth, nHeight);
                            getImg.src = '';
                            getImg = null;
                        };

                        getImg.src = imgURL;
                    }
                    else if (options.scale == 'width') {
                        storedOptions.GetRoomSceneDimensions.call(null, maxWidth, null);
                    }
                    else {
                        getImg.onload = function () {
                            ratio = 0;
                            wDim = getImg.width;
                            hDim = getImg.height;
                            var nWidth = 0;
                            var nHeight = 0;

                            if (wDim > maxWidth) {
                                ratio = maxWidth / wDim;   // get ratio for scaling image
                                nWidth = hDim * ratio;    // Reset width to match scaled image
                                nHeight = hDim * ratio;    // Reset height to match scaled image
                            }

                            // Check if current height is larger than max
                            if (hDim > maxHeight) {
                                ratio = maxHeight / hDim; // get ratio for scaling image
                                nWidth = wDim * ratio;    // Reset width to match scaled image
                                nHeight = hDim * ratio;    // Reset height to match scaled image
                            }

                            nWidth = Math.floor(nWidth);
                            nHeight = Math.floor(nHeight);

                            storedOptions.GetRoomSceneDimensions.call(null, nWidth, nHeight);
                            getImg.src = '';
                            getImg = null;
                        };

                        getImg.src = imgURL;

                    }


                });

            };

            internal.applyRealWorld = function (options) {
                this.each(function () {
                    var surfaces = $(this).ChamViz({ action: 'getSurfaceDataArray' });
                    for (var i = 0; i < surfaces.length; i++) {
                        $(this).ChamViz({
                            action: 'apply',
                            surface: surfaces[i].sortorder,
                            sel: options.sel
                        });
                    }
                });
            };


            internal.internProducts = function internProducts(options) {
                this.each(function () {
                    var interned = {};
                    var products = options.products;
                    if (products == void 0) { if (console) console.log('Error: products not found in internProducts'); return; }
                    var isArray = Array.isArray || function (arr) { return Object.prototype.toString.call(arr) == '[object Array]'; };

                    if (isArray(products) && products.length > 0) {
                        if ((products[0].hasOwnProperty('specifier') || products[0].hasOwnProperty('Specifier')) && (products[0].hasOwnProperty('id') || products[0].hasOwnProperty('ID'))) {
                            for (var i = products.length - 1; i > -1; i--) {
                                //interned.push({id:products[i].id || products[i].ID, spec:products[i].specifier || products[i].Specifier});
                                interned[products[i].id || products[i].ID] = products[i].specifier || products[i].Specifier;
                            }
                        }
                    }
                    else {
                        for (var prop in products) {
                            if (products.hasOwnProperty(prop)) {
                                var rows = products[prop];
                                if (isArray(rows)) {
                                    if (rows.length > 0 && (rows[0].hasOwnProperty('specifier') || rows[0].hasOwnProperty('Specifier')) && (rows[0].hasOwnProperty('id') || rows[0].hasOwnProperty('ID'))) {
                                        for (var i = rows.length - 1; i > -1; i--) {
                                            //interned.push({id:rows[i].id || rows[i].ID, spec:rows[i].specifier || rows[i].Specifier});
                                            interned[rows[i].id || rows[i].ID] = rows[i].specifier || rows[i].Specifier;
                                        }
                                    }
                                } else {
                                    if ((rows.hasOwnProperty('specifier') || rows.hasOwnProperty('Specifier')) && (rows.hasOwnProperty('id') || rows.hasOwnProperty('ID'))) interned.push({ id: rows.id || rows.ID, spec: rows.specifier || rows.Specifier });
                                    else {
                                        for (var rs in rows) {
                                            if (rows.hasOwnProperty(rs)) {
                                                var r = rows[rs];
                                                //if((r.hasOwnProperty('specifier') || r.hasOwnProperty('Specifier')) && (r.hasOwnProperty('id') ||r.hasOwnProperty('ID'))) interned.push({id:r.id || r.ID, spec:r.specifier || r.Specifier});
                                                if ((r.hasOwnProperty('specifier') || r.hasOwnProperty('Specifier')) && (r.hasOwnProperty('id') || r.hasOwnProperty('ID'))) interned[r.id || r.ID] = r.specifier || r.Specifier;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                    interned[0] = "~";  // null
                    interned[-1] = "?";  // empty
                    jQuery.data(this, "interned", interned);
                });
            }


            internal._fullSellToApply = function _fullSellToApply(fullsel, internProducts) {
                fullsel = String(fullsel);
                var arr = [], parnCount = 0; tmp = '';
                fullsel.replace(/./g, function (m) {
                    if (m == ' ') return m;
                    if (m == ',') { arr.push(tmp); arr.push(','); tmp = ''; return m; }
                    if (m == '(') { arr.push(tmp); arr.push('('); tmp = ''; return m; }
                    if (m == ')') { arr.push(tmp); arr.push(')'); tmp = ''; return m; }
                    tmp += m;
                    return m;
                });
                arr.push(tmp);

                if (arr.length > 30) return null; //set a limit
                var apply = "(APPLY=(";
                var output = apply;
                var comma = '';
                for (i = 0; i < arr.length; i++) {
                    var sel = arr[i];
                    if (sel == '') continue;
                    else if (sel == '(') output += apply
                    else if (sel == ')') output += '))'
                    else if (sel == ',') output += ','
                    else {
                        var v = internProducts[sel];
                        if (v == void 0) return null;
                        output += v;
                    }
                }
                output += "))";
                return output;
            };




            //Check if this is a API action request
            if (options.action) {
                return internal[options.action].call(this, options);
            }

            //Past this point this is Init

            //Set the default values, use comma to separate the settings, example:

            var defaults = {
                "spec": "",
                "defaultSpec": "",
                "width": "",
                "renderserver": "//core2render.chameleonpower.com/cham/",
                "renderFlags": "",
                "siteid": "",
                "hightlightColor": 'E40514',
                "sel": "",
                "defaultSel": "",
                "showZoom": false,
                "showMourseOvers": true,
                "mouseOverPreSize": 200,
                "surfacesLoaded": function (surfaceObjArr) { },  //when we have surface Data; maybe make menus, etc
                "surfaceHighlighted": function (surfaceObj) { },
                "surfaceUnHighlighted": function (surfaceObj) { },
                "chooseSurfaceHandler": function (surfaceObj) { },
                "applySurfaceHandler": function (img, surfaceObj, ChamVizContainer, productName, sel) { /*this is element wrapped by ChamViz  */ },  //waiting msg, applied products
                "srcBuilderHandler": function (src, width, siteid, productName, renderserver, sessionId, renderFlags, spec, sel, specSurfNum) { return src; },
                "clearAllHandler": function () { },
                'GetRoomSceneDimensions': function (w, h) { },
                "AllSurfacesLoaded": function () { },
                "initComplete": function () { },
                "surfaces": [],
                "areamaps": [],
                "legacyHitTestData": false,
                "baseImageRenderMode": false,
                "isResponsive": true,
                "responsiveMode": "fit width", // "fit width" | "contain" | "cover"
                "responsiveAlignment": "center top", // Horizontal alignment followed by vertical.
                "responsiveRoot": null, // JQuery. Specifies a sizing root for the "contain" responsive mode. The element ChamViz is inited on does not need a width defined when using this.
                "responsiveBreakpoints": null, // { minWidth: number, mode: string, orientation?: 'portrait' | 'landscape', alignment?: string, root?: JQuery }[]
                "zoomWidth": 2000,
                "LoadZoomMessage": "",
                "chamWebToolsPath": "/ChamWebTools/",
				"version": "3.1"
            };
            if (typeof options.highlightColor != 'undefined') options.hightlightColor = options.highlightColor;
            if (typeof options.showMouseOvers != 'undefined') options.showMourseOvers = options.showMouseOvers;
            var options = $.extend(defaults, options);
            options.sessionId = internal.createGUID();


            var responsive = {};
            var $window = $(window);

            responsive.getActiveBreakpoint = function () {
                var breakpoints = options.responsiveBreakpoints;
                if (breakpoints == null)
                    return options.responsive;

                var windowWidth = $window.width();
                var isLandscape = (windowWidth / $window.height()) > 1; // A square is defined as portrait in the CSS spec.
                var activeBreakpoint = null;

                for (var index = 0, length = breakpoints.length; index < length; index++) {
                    var breakpoint = breakpoints[index];

                    if (windowWidth >= breakpoint.minWidth && (breakpoint.orientation == null || (breakpoint.orientation === 'landscape' ? isLandscape : !isLandscape))
                        && (activeBreakpoint == null || breakpoint.minWidth > activeBreakpoint.minWidth))
                        activeBreakpoint = breakpoint;
                }

                return activeBreakpoint || options.responsive;
            };

            responsive.widthCalculators = {
                'fit width': function (root) {
                    return Math.round(root.width());
                },
                'contain': function (root) {
                    var bounds = root.get(0).getBoundingClientRect();
                    var containerRatio = bounds.width / bounds.height;
                    var imageWidth = containerRatio > options.ratio ? (bounds.height * options.ratio) : bounds.width;

                    return Math.ceil(imageWidth);
                },
                'cover': function (root) {
                    var bounds = root.get(0).getBoundingClientRect();
                    var containerRatio = bounds.width / bounds.height;
                    var imageWidth = containerRatio > options.ratio ? bounds.width : (bounds.height * options.ratio);

                    return Math.ceil(imageWidth);
                }
            };

            responsive.getWidthCalculator = function (responsiveMode) {
                return responsive.widthCalculators[responsiveMode] || responsive.widthCalculators['fit width'];
            };

            var horizontalValid = ['left', 'center', 'right'];
            var verticalValid = ['top', 'middle', 'bottom'];
            var validModes = ['contain', 'cover', 'fit width'];

            var allClasses = [
                'viz-contain', 'viz-cover', 'viz-fit-width', 'viz-align-left', 'viz-align-center', 'viz-align-right', 'viz-align-top',
                'viz-align-middle', 'viz-align-bottom'
            ];

            responsive.getClasses = function (mode, alignment) {
                var options = alignment.indexOf(' ') > -1 ? alignment.split(' ') : [alignment, 'top'];
                var first = options[0];
                var second = options[1];

                options[0] = horizontalValid.indexOf(first) > -1 ? 'viz-align-' + first : 'viz-align-center';
                options[1] = verticalValid.indexOf(second) > -1 ? 'viz-align-' + second : 'viz-align-top';

                var modeClass = validModes.indexOf(mode) > -1 ? 'viz-' + mode.replace(' ', '-') : 'viz-fit-width';
                options.unshift(modeClass);

                return options;
            };

            responsive.updateElementClasses = function (rootEl, classes) {
                var current = rootEl.className.split(' ');
                var newClasses = [];

                for (var index = 0, length = current.length; index < length; index++) {
                    var className = current[index];

                    if (allClasses.indexOf(className) === -1)
                        newClasses.push(className);
                }

                for (var index = 0, length = classes.length; index < length; index++)
                    newClasses.push(classes[index]);

                rootEl.className = newClasses.join(' ');
            };


            var _getRoomsceneData = function (rootEl, specifier, sel, dataObject) {
                dataObject.surfaces = {};
                return $.ajax({
                    type: "GET",
                    url: options.chamWebToolsPath + 'ChamViz/RoomsceneJSON2.ashx?specifier=' + encodeURIComponent(dataObject.spec) + '&sel=' + dataObject.sel + '&width=' + dataObject.width + '&r=' + Math.random(),
                    beforeSend: function (x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/j-son;charset=UTF-8");
                        }
                    },
                    dataType: "json",
                    success: function (data) {
                        jQuery.extend(true, dataObject, data);
                    }
                });
            };

        var _draw = function (rootEl, options) {
          var root = $(rootEl);
          var uniqueId = 'ChamViz' + $('.ChamViz').length;
          var hitTestLookup = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!#$%&()*+,-.:;<=>?@[]^_`{|}~";
          try {
            window.sessionStorage.setItem('_spec', options.spec); //for analyics
          }catch(error) { }




                var isCanvasSupported = function () {
                    if (document.all && !document.addEventListener) return false; // IE8 or less, see: http://tanalin.com/en/articles/ie-version-js/
                    if (!window.XMLHttpRequest) return false;
                    var elem = document.createElement('canvas');
                    return !!(elem.getContext && elem.getContext('2d'));
                }

                if (options.surfaces.length > 0) {

                    var doneInterval = window.setInterval(
                            function () {
                                var done = true;
                                var els = root.find('img.viz-layer');
                                if (els.length == 0) return;
                                els.each(function () {
                                    if (this.complete != true) done = false;
                                });
                                if (done) cleardoneInterval();
                            }, 200);

                    var cleardoneInterval = function () { window.clearInterval(doneInterval); options.AllSurfacesLoaded.call(this); $(this).trigger('initComplete'); };

                }


                var c = [];

                if (options.isResponsive) {
                    // The base responsive mode can be defined this way or by adding a breakpoint with minWidth: 0.
                    if (options.responsiveMode != null) {
                        var classes = responsive.getClasses(options.responsiveMode, options.responsiveAlignment);
                        var sizingRoot = options.responsiveMode === 'contain' && options.responsiveRoot !== null ? options.responsiveRoot : root;
                        var calculateWidth = responsive.getWidthCalculator(options.responsiveMode);

                        options.responsive = { mode: options.responsiveMode, alignment: options.responsiveAlignment, root: sizingRoot, classes: classes, calculateWidth: calculateWidth };
                    }

                    if (options.responsiveBreakpoints != null) {
                        var length = options.responsiveBreakpoints.length;

                        for (var index = 0; index < length; index++) {
                            var breakpoint = options.responsiveBreakpoints[index];
                            var alignment = breakpoint.alignment || options.responsiveAlignment;

                            breakpoint.classes = responsive.getClasses(breakpoint.mode, alignment);
                            breakpoint.calculateWidth = responsive.getWidthCalculator(breakpoint.mode);

                            if (breakpoint.mode !== 'contain' || breakpoint.root == null)
                                breakpoint.root = root;
                        }
                    }

                    var active = responsive.getActiveBreakpoint();

                    options.width = active.calculateWidth(active.root);
                    options.activeBreakpoint = active;

                    if (active.root.get(0) !== rootEl)
                        active.root.addClass('viz-sizing-root');

                    responsive.updateElementClasses(rootEl, active.classes);
                }

                c.push('<style>');

                c.push('.ChamVizWrapper { clear: both; -webkit-touch-callout: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; } ');
                c.push('.ChamViz { position: relative; overflow: hidden; } ');
                c.push('.ChamViz img { width: inherit; height: inherit; border: none; } ');
                c.push('.viz-control { width: 100%; height: 100%; } ');
                c.push('.viz-layer, .viz-highlight, .viz-control, .ZoomLayer, .zoom-control { position: absolute; top: 0; left: 0; } ');

                c.push('.viz-baseimage { display: block; z-index: 0; } ');
                c.push('.viz-highlight { display: none; -moz-opacity: 0.5; -khtml-opacity: 0.5; filter: alpha(opacity=50); opacity: 0.5; } ');
                c.push('.ZoomLayer { width: 100%; height: 100%; overflow: hidden; display: none; } ');
                c.push('.zoom-control { display: none; cursor: pointer; } ');

                c.push('.viz-fit-width .ChamViz { width: 100%; } ');
                c.push('.viz-contain .ChamViz { width: auto; height: auto; display: inline-block; vertical-align: top; } ');
                c.push('.viz-contain .ChamViz img { max-width: 100%; max-height: 100%; } ');
                c.push('.viz-cover { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; } ');
                c.push('.viz-cover .ChamViz { min-width: 100%; min-height: 100%; -webkit-box-flex: 0; -ms-flex: 0 0 auto; flex: 0 0 auto; } ');
                c.push('.viz-sizing-root .viz-contain { display: inline-block; vertical-align: top; } ');

                c.push('.viz-align-center.viz-contain, .viz-align-center.viz-cover { text-align: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; } ');
                c.push('.viz-align-right.viz-contain, .viz-align-right.viz-cover { text-align: right; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; } ');
                c.push('.viz-align-middle { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } ');
                c.push('.viz-align-bottom { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end; }');

                c.push('</style>');


                c.push('<div class="ChamViz">');
                c.push('<img class="viz-baseimage" src="' + options.renderserver + 'GetImage.ashx?Specifier=' + options.spec + '&Type=Base&Size=' + options.width + '&SiteID=' + options.siteid + options.renderFlags + '" />');

                var GetLayerFromSel = function (sel, surfaceNum, spec) {
                    var r = tImg;
                    if (sel != '' && sel != '-1' && sel != 'undefined') r = options.renderserver + "GetImage.ashx?Specifier=" + spec + "&Type=Layer&Size=" + options.width + "&SiteID=" + options.siteid + options.renderFlags + "&Sel=" + sel + "&SurfaceNum=" + surfaceNum;
                    if (options.srcBuilderHandler != void 0) r = options.srcBuilderHandler.call(this, r, options.width, options.siteid, '', options.renderserver, '', options.renderFlags, spec, sel, surfaceNum);
                    return r;
                };

                var _zindex = 1;

                for (var i = 0, len = options.surfaces.length; i < len; ++i) {
                    var surface = options.surfaces[i];
                    c.push('<img class="viz-layer" style="z-index: ' + _zindex++ + ';" src="' + GetLayerFromSel(surface.sel, surface.specifiersurfacenumber, surface.specifier) + '" data-sel="' + surface.sel + '" data-surface="' + surface.sortorder + '" data-name="' + surface.name + '" data-specifier="' + surface.specifier + '" data-type="' + surface.type + '" data-seriesid="' + surface.seriesid + '" data-roomsceneauxiliaryseriesid="' + surface.roomsceneauxiliaryseriesid + '"  data-specifiersurfacenumber="' + surface.specifiersurfacenumber + '" />');
                }

                if (options.showMourseOvers) {
                    for (var i = 0, len = options.surfaces.length; i < len; ++i) {
                        var surface = options.surfaces[i];
                        c.push('<img class="viz-highlight" style="z-index: ' + _zindex++ + ';" src="' + options.renderserver + 'GetImage.ashx?Specifier=' + surface.specifier + '&Type=MouseOver&SurfaceNum=' + surface.specifiersurfacenumber + '&Color=' + options.hightlightColor + '&SizeMode=Width&Size=' + (options.isResponsive ? options.width : options.mouseOverPreSize) + '&Log=0' + options.renderFlags + '" data-surface="' + surface.sortorder + '" />');
                    }
                }

                c.push('<div class="viz-control" style="z-index: ' + _zindex++ + ';"><img class="controlImage" src="' + tImg + '" usemap="#' + uniqueId + 'MAP' + '" /></div>');

                // TODO:palettes

                //OLD MouseOvers;areaMaps
                var useHitTest = false;
                if (options.showMourseOvers && !isCanvasSupported()) {
                    if (options.areamaps.length > 0) useHitTest = !(options.areamaps[0].isAreaMap == 'true');
                    if (!useHitTest) {
                        c.push('<map id="' + uniqueId + 'MAP' + '" name="' + uniqueId + 'MAP' + '">');
                        for (var i = 0, len = options.areamaps.length; i < len; ++i) {
                            var areamap = options.areamaps[i];
                            c.push('<area class="ztip" style="cursor:pointer" title="' + areamap.name + '" data-surface="' + areamap.sortorder + '" data-name="' + areamap.name + '" shape="poly" coords="' + areamap.coordinates + '" />');
                        }
                        c.push('</map>');
                    }
                }

                c.push('</div>'); //end .ChamViz

                //zoom
                c.push('<div class="ZoomLayer" style="z-index: ' + _zindex++ + ';" data-orient="center" data-canzoom="yes"><div></div></div>');
                c.push('<div class="zoom-control" style="z-index: ' + _zindex++ + ';"></div>');

                rootEl.innerHTML = c.join('');


                var isIpad = navigator.userAgent.match(/iPad/i) != null;
                //check if canvas is supported
                if (isCanvasSupported()) {

                    var CreateHTML5Mouseovers = function (baseImage, highlights) {
                        //var theDiv = document.getElementById(divId);
                        var arr = highlights.toArray();
                        if (arr.length < 1) return;

                        var createMapCanvas = function (w, h) {
                            var canvas = document.createElement('canvas');
                            canvas.width = w;
                            canvas.height = h;
                            var ctx = canvas.getContext("2d");
                            ctx.fillStyle = "#000000";
                            ctx.fillRect(0, 0, w, h);
                            return canvas;
                        };

                        var timg = new Image();
                        timg.crossOrigin = "Anonymous";
                        timg.onload = function () {
                            var MapCanvas = createMapCanvas(timg.width, timg.height);
                            var isCorsSupport = 'withCredentials' in new XMLHttpRequest();

                            var processImg = function (mapCanvas, img, surfaceNum) {
                                window.URL = window.URL || window.webkitURL;
                                var xhr = new XMLHttpRequest();

                                xhr.onload = function () {
                                    var _img = new Image();
                                    //_img.crossOrigin = "Anonymous";
                                    _img.onload = function () {
                                        var canvas = document.createElement('canvas');
                                        canvas.width = mapCanvas.width;
                                        canvas.height = mapCanvas.height;
                                        var ctx = canvas.getContext("2d");
                                        ctx.drawImage(_img, 0, 0, mapCanvas.width, mapCanvas.height);
                                        //loop thru, check pixels, add to MapCanvas
                                        var MCctx = MapCanvas.getContext("2d");
                                        var MCD = MCctx.getImageData(0, 0, mapCanvas.width, mapCanvas.height);
                                        var MCData = MCD.data;
                                        var layerdata = ctx.getImageData(0, 0, mapCanvas.width, mapCanvas.height).data;
                                        // iterate over all pixels
                                        for (var i = 0, n = layerdata.length; i < n; i += 4) {
                                            //var r = layerdata[i];
                                            //var g = layerdata[i + 1];
                                            //var b = layerdata[i + 2];
                                            var a = layerdata[i + 3];
                                            //if (r > 0 || g > 0 || b > 0) {
                                            if (a > 30) {
                                                if (surfaceNum > MCData[i]) MCData[i] = surfaceNum;  //put in red slot
                                            }
                                        }
                                        MCctx.putImageData(MCD, 0, 0);
                                        if (options.legacyHitTestData) {
                                            var endArr = [];
                                            for (var i = 0, n = layerdata.length; i < n; i += 4) {
                                                var r = MCData[i];
                                                endArr.push(hitTestLookup.charAt(r));
                                            }

                                            var _ddata = { width: MapCanvas.width };
                                            _ddata.data = endArr.join('');
                                            options.legacyHitTestData = _ddata;
                                        }

                                        canvas = null; // Attempt to fix out of memory error occuring in some situations.
                                        _img = null;
                                    }

                                    var uInt8Array = new Uint8Array(this.response || this.responseText);
                                    var i = uInt8Array.length;
                                    var binaryString = new Array(i);
                                    while (i--) {
                                        binaryString[i] = String.fromCharCode(uInt8Array[i]);
                                    }
                                    var data = binaryString.join('');

                                    var base64 = window.btoa(data);
                                    _img.width = timg.width;
                                    _img.height = timg.height;
                                    _img.src = "data:image/png;base64," + base64;
                                }

                                // XDomainRequest in IE browsers only supports plain text so cannot be used to load an image.
                                var xhrUrl = isCorsSupport ? img.src : (options.chamWebToolsPath + 'ChamViz/RenderProxy.ashx?url=' + encodeURIComponent(img.src));

                                xhr.open('GET', xhrUrl, true);
                                xhr.responseType = 'arraybuffer';
                                xhr.send();
                            };


                            for (var i = 0; arr[i]; ++i) {
                                var s = arr[i].getAttribute('data-surface');
                                processImg(MapCanvas, arr[i], s);
                            }


                            var ht_MCctx = MapCanvas.getContext("2d");
                            var ht_scan = MapCanvas.width * 4;

                            var hittest = function hittest(x, y) {
                                x = x << 0;
                                y = y << 0;
                                //calc index for x & y
                                var ht_MCData = ht_MCctx.getImageData(0, 0, MapCanvas.width, MapCanvas.height).data;
                                var index = (ht_scan * (y - 1)) + (x * 4);
                                //check red slot
                                var r = ht_MCData[index];
                                if (r > 0) return r;
                                else return -1;
                            };

                            var zoomControl = root.find('div.zoom-control');

                            root.find('div.viz-control')
                                .off('mousemove mouseout click touchstart touchend touchcancel')
                                .on('mousemove touchstart', function (e) {
                                    var changedTouches = e.originalEvent.changedTouches;
                                    if (changedTouches != null && (changedTouches.length > 1 || e.originalEvent.touches.length > 1))
                                        return;

                                    var self = $(this);
                                    if (self.hasClass('frozen')) return;

                                    highlights.css('display', 'none');
                                    var offset = self.offset();
                                    var relX = (e.pageX || (changedTouches != null ? changedTouches[0].pageX : null)) - offset.left;
                                    var relY = (e.pageY || (changedTouches != null ? changedTouches[0].pageY : null)) - offset.top;
                                    var s = hittest.call(null, relX, relY);
                                    if (typeof s == 'undefined') return;

                                    if (this.currentSurface != null && this.currentSurface !== s) {
                                        var oldSurface = options.surfaces[this.currentSurface - 1];
                                        options.surfaceUnHighlighted.call(root, oldSurface);

                                        if (e.type === 'mousemove')
                                            root.trigger('surfaceTooltipClose', oldSurface);
                                    }

                                    var isFreshHighlight = this.currentSurface !== s;
                                    this.currentSurface = s < 1 ? null : s;
                                    if (s < 1) return;

                                    highlights.eq(s - 1).css('display', 'block');

                                    if (isFreshHighlight) {
                                        var surface = options.surfaces[s - 1];
                                        options.surfaceHighlighted.call(root, surface);

                                        if (e.type === 'mousemove')
                                            root.trigger($.Event('surfaceTooltipOpen', { originalEvent: e }), surface);
                                    } else if (e.type === 'mousemove')
                                        root.trigger($.Event('surfaceTooltipMove', { originalEvent: e }), options.surfaces[s - 1]);
                                })
                                .on('click touchend', function (e) {
                                    // On touch devices, prevents mousemove from being fired after touchend.
                                    // This will also work if placed in the touchstart handler, hovwever calling e.preventDefault() there will prevent page scrolling over the visualizer.
                                    e.preventDefault();

                                    var changedTouches = e.originalEvent.changedTouches;
                                    if (changedTouches != null && (changedTouches.length > 1 || e.originalEvent.touches.length > 1))
                                        return;

                                    var self = $(this);
                                    if (self.hasClass('frozen')) return;

                                    /* This code block is here and not after the if (s < 1) return for a reason. The reason is that one can drag the finger to a
                                       non-mapped part of the room scene resulting in a -1 hittest. This is also why this.currentSurface must be used. */
                                    var lastSurface = this.currentSurface;
                                    this.currentSurface = null;
                                    highlights.css('display', 'none');
                                    options.surfaceUnHighlighted.call(root, options.surfaces[lastSurface - 1]);

                                    var offset = self.offset();
                                    var relX = (e.pageX || (changedTouches != null ? changedTouches[0].pageX : null)) - offset.left;
                                    var relY = (e.pageY || (changedTouches != null ? changedTouches[0].pageY : null)) - offset.top;
                                    var s = hittest.call(null, relX, relY);
                                    if (typeof s == 'undefined') return;
                                    if (s < 1) return;

                                    if (lastSurface !== s) // Simulates touch cancel when dragging finger from one surface to another.
                                        return;

                                    e.stopPropagation();
                                    options.chooseSurfaceHandler.call(root, options.surfaces[s - 1]);

                                    //hide zoom
                                    zoomControl.hide();
                                })
                                .on('mouseout touchcancel', function (e) {
                                    if ($(this).hasClass('frozen')) return;
                                    var s = this.currentSurface;
                                    highlights.css('display', 'none');

                                    this.currentSurface = null;
                                    if (s == null)
                                        return;

                                    var surface = options.surfaces[s - 1];
                                    options.surfaceUnHighlighted.call(root, surface);

                                    if (e.type === 'mouseout')
                                        root.trigger($.Event('surfaceTooltipClose', { originalEvent: e }), surface);
                                });
                        }

                        timg.src = baseImage.attr('src');
                    };

                    var baseImage = root.find('img.viz-baseimage');
                    var highlights = root.find('img.viz-highlight');
                    var layers = root.find('img.viz-layer');

                    CreateHTML5Mouseovers(baseImage, highlights);

                    var onResizeEnd = function () {
                        baseImage.attr('src', options.renderserver + 'GetImage.ashx?Specifier=' + options.spec + '&Type=Base&Size=' + options.width + '&SiteID=' + options.siteid + options.renderFlags);
                        CreateHTML5Mouseovers(baseImage, highlights);

                        for (var i = 0, len = options.surfaces.length; i < len; ++i) {
                            var layer = layers.eq(i);
                            var surface = options.surfaces[i];

                            layer.attr('src', GetLayerFromSel(layer.attr('data-sel'), surface.specifiersurfacenumber, surface.specifier));
                        }

                        if (options.showMourseOvers)
                            for (var i = 0, len = options.surfaces.length; i < len; ++i) {
                                var surface = options.surfaces[i];
                                highlights.eq(i).attr('src', options.renderserver + 'GetImage.ashx?Specifier=' + surface.specifier + '&Type=MouseOver&SurfaceNum=' + surface.specifiersurfacenumber + '&Color=' + options.hightlightColor + '&SizeMode=Width&Size=' + options.width + '&Log=0' + options.renderFlags);
                            }
                    }

                    var resizeId;
                    var chamViz = root.find('.ChamViz');

                    if (options.isResponsive) {
                        var eventNames = 'resize.visualizer.' + uniqueId + ' orientationchange.visualizer.' + uniqueId;

                        $(window).off(eventNames).on(eventNames, function () {
                            var active = responsive.getActiveBreakpoint();
                            var pastActive = options.activeBreakpoint;

                            options.width = active.calculateWidth(active.root);
                            chamViz.css('width', active.mode === 'contain' || active.mode === 'cover' ? options.width : '');

                            if (active !== pastActive) {
                                if (pastActive.root.get(0) !== rootEl)
                                    pastActive.root.removeClass('viz-sizing-root');
                                if (active.root.get(0) !== rootEl)
                                    active.root.addClass('viz-sizing-root');

                                responsive.updateElementClasses(rootEl, active.classes);
                                options.activeBreakpoint = active;
                            }

                            clearTimeout(resizeId);
                            resizeId = setTimeout(onResizeEnd, 500);
                        });
                    }
                }
                else {

                    if (!useHitTest) {
                        ///Old mouse over


                        root.find('area').unbind("mouseover").bind('mouseover', function (e) {
                            var el = e.target;
                            var $root = $(el).parents('div.ChamViz');
                            var surface = el.getAttribute('data-surface');
                            if ($root.find('div.viz-control').hasClass('frozen')) return;
                            if (isIpad) { $(el).click(); }

                            $root.find('.controlImage').css('cursor', 'pointer');
                            $root.find('img.viz-highlight').eq(surface - 1).css('display', 'block');
                            options.surfaceHighlighted.call(root, options.surfaces[parseInt(surface) - 1]);
                        }).unbind("mouseout").bind('mouseout', function (e) {
                            var el = e.target;
                            if ($(el).hasClass('frozen')) return;
                            var $root = $(el).parents('div.ChamViz');
                            var surface = el.getAttribute('data-surface');

                            $root.find('.controlImage').css('cursor', 'default');
                            $root.find('img.viz-highlight').eq(surface - 1).css('display', 'none');
                            options.surfaceUnHighlighted.call(root, options.surfaces[parseInt(surface) - 1]);
                        }).unbind("click").bind('click', function (e) {
                            var el = e.target;
                            var $root = $(el).parents('div.ChamViz');
                            var surface = el.getAttribute('data-surface');

                            if ($root.find('div.viz-control').hasClass('frozen')) return;
                            options.chooseSurfaceHandler.call(root, options.surfaces[parseInt(surface) - 1]);
                            e.stopPropagation();
                            //hide zoom

                            $root.find('div.zoom-control').hide();
                        });


                    }
                    else { //hittest code for IE8-
                        var __data = options.areamaps[0].coordinates;
                        var __width = options.areamaps[0].origwidth;
                        var __scale = __width / options.width;
                        var legacyHitTest = function (x, y) {
                            x = (x * __scale) << 0;
                            y = (y * __scale) << 0;
                            var index = ((y - 1) * __width) + x;
                            var r = __data[index];
                            r = hitTestLookup.indexOf(r)
                            if (r > 0) return r;
                            else return -1;
                        };


                        root.find('div.viz-control').unbind("mousemove").bind('mousemove', function (e) {
                            var el = e.target;
                            if ($(this).hasClass('frozen')) return;
                            if (isIpad) { $(el).click(); }

                            $(this).parent().find('img.viz-highlight').css('display', 'none');
                            var parentOffset = $(this).parent().offset();
                            var relX = e.pageX - parentOffset.left;
                            var relY = e.pageY - parentOffset.top;
                            var s = legacyHitTest.call(null, relX, relY);
                            if (typeof s == 'undefined') return;
                            if (s < 1) return;
                            this.currentSurface = s;
                            $(this).parent().find('img.viz-highlight').eq(s - 1).css('display', 'block');
                            options.surfaceHighlighted.call(root, options.surfaces[s - 1]);

                        }).unbind("mouseout").bind('mouseout', function (e) {
                            var s = this.currentSurface || 1;
                            if ($(this).hasClass('frozen')) return;
                            $(this).parent().find('img.viz-highlight').css('display', 'none');
                            options.surfaceUnHighlighted.call(root, options.surfaces[s - 1]);
                        });

                        root.find('div.viz-control').unbind("click").bind('click', function (e) {
                            if ($(this).hasClass('frozen')) return;
                            var parentOffset = $(this).parent().offset();
                            var relX = e.pageX - parentOffset.left;
                            var relY = e.pageY - parentOffset.top;
                            var s = legacyHitTest.call(null, relX, relY);
                            if (typeof s == 'undefined') return;
                            if (typeof s == 0) return;

                            options.chooseSurfaceHandler.call(root, options.surfaces[s - 1]);
                            e.stopPropagation();
                            //hide zoom
                            //var $root = $(el).parents('div.ChamViz');
                            root.find('div.zoom-control').hide();
                        });
                    }
                }

                //Make sure everything is the correct size
                root.find('img.viz-baseimage').on('load', function () {
                    options.baseWidth = this.width;
                    options.baseHeight = this.height;

                    if (options.showZoom) root.find('div.zoom-control').show();
                }).each(function () {
                    if (!this.complete) $(this).on('load');
                });
                root.find('img.viz-baseimage').mouseover(function () {  //if this is called we have an issue
                    $(this).on('load');
                });

                options.surfacesLoaded.call(rootEl, options.surfaces);


            }; //end _draw

            var undoQ = function () {
                var q = {};

                q.executed = [];
                q.unexecuted = [];

                q.execute = function execute(cmd) {
                    cmd.execute();
                    q.executed.push(cmd);

                    if (q.unexecuted.length > 0)
                        q.unexecuted = [];
                };

                q.undo = function undo() {
                    var cmd1 = q.executed.pop();
                    if (cmd1 !== undefined) {
                        if (cmd1.unexecute !== undefined) {
                            cmd1.unexecute();
                        }
                        q.unexecuted.push(cmd1);
                    }
                };

                q.redo = function redo() {
                    var cmd2 = q.unexecuted.pop();
                    if (cmd2 === undefined)
                        return;

                    cmd2.execute();
                    q.executed.push(cmd2);
                };
                q.reset = function reset() {
                    q.executed = [];
                    q.unexecuted = [];
                }

                return q;
            };







            return this.each(function () {
                var vizContainer = $(this);
                var getURLParameter = function getURLParameter(name) {
                    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)', 'i').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null;
                }
                var o = options;
                if (o.spec == '') o.spec = getURLParameter('specifier');
                if (!o.spec) o.spec = o.defaultSpec;
                if (o.sel == '') o.sel = getURLParameter('sel');
                if (!o.sel) o.sel = (o.defaultSel) ? o.defaultSel : '-1';
                if (o.width == '') o.width = parseInt(vizContainer.width());
                if (o.spec && o.spec.toLowerCase().indexOf('_user') > -1) $('body').addClass('IMContains');
                var promise;
                if (typeof sceneData == 'undefined') {
                    promise = _getRoomsceneData(this, o.spec, o.sel, o);
                } else {
                    jQuery.extend(true, o, sceneData);
                    promise = $.Deferred().resolve().promise();
                }

                this.innerHTML = '';
                vizContainer.addClass('ChamVizWrapper ChamVisualizerPlugin');

                var q = undoQ();
                jQuery.data(this, "undoQ", q);
                jQuery.data(this, "options", options);
                jQuery.data(this, "history", []);
                jQuery.data(this, "history").push({ 'full': o.sel, 'sel': '-1', 'surface': 0 });

                var rootEl = this;
                promise.done(function () {
                    _draw(rootEl, o);
                    options.initComplete.call(rootEl);
                });

              if (window.ChamStats != void 0 && o != void 0) window.ChamStats.sendData('roomscene', o.spec);


                //Below allows you to apply 1x1, 6x6, and 12x12 real world size product on every surface.
                var isCtrl = false;
                var isShift = false;

                $(document)
                    .on('keydown', function (e) {
                        if (window.disableChamVizTesting != void 0) return;
                        if (e.which == 17) isCtrl = true;
                        if (e.which == 16) isShift = true;
                        if (e.which == 49 && isCtrl == true && isShift == true) { // Keyboard event: CTRL + SHIFT + 1
                            e.preventDefault();
                            internal['applyRealWorld'].call(vizContainer, { sel: 165644 }); // 1x1 Real World Sel
                            isCtrl = false;
                            isShift = false;
                        }
                        if (e.which == 50 && isCtrl == true && isShift == true) {// Keyboard event: CTRL + SHIFT + 2
                            e.preventDefault();
                            internal['applyRealWorld'].call(vizContainer, { sel: 165653 }); // 6x6 Real World Sel
                            isCtrl = false;
                            isShift = false;
                        }
                        if (e.which == 51 && isCtrl == true && isShift == true) {// Keyboard event: CTRL + SHIFT + 3
                            e.preventDefault();
                            internal['applyRealWorld'].call(vizContainer, { sel: 142745 }); // 12x12 Real World Sel
                            isCtrl = false;
                            isShift = false;
                        }
                    })
				    .on('keyup', function (e) {
				        if (e.which == 17) isCtrl = false;
				        if (e.which == 16) isShift = false;
				    });

                if (window.ChamStats == void 0) {
                    var scrpt = document.createElement('script');
                    scrpt.src = '//ca.chameleonpower.com/stats.js';
                    document.head.appendChild(scrpt);
                }
            });
        }
    });
})(jQuery);




/*  Util APIs  */
if (typeof Viz == 'undefined') Viz = {};
Viz.combineSels = function combineSels(cur, old) {  //overrides non-applied sels
    if (typeof old == 'undefined') return cur;
    var c = cur.split(','), o = old.split(',');
    var i = -1, v = c.length;
    while (++i < v) if (c[i] == '-1' && o[i] != '-1' && o[i] != null) c[i] = o[i];
    return c.join(',');
};

Viz.sel = {
    parseToSurfaceArray: function parseToSurfaceArray(sel) {
        var out = [], parnCount = 0; tmp = '';
        sel.replace(/./g, function (m) {
            if (m == ' ') return m;
            if (m == ',' && parnCount == 0) { out.push(tmp); tmp = ''; return m; }
            tmp += m;
            if (m == '(') parnCount++;
            if (m == ')') parnCount--;
            return m;
        });
        out.push(tmp);
        return out;
    }
    , isEmpty: function isEmpty(sel) {
        if (sel == null) return true;
        sel = String(sel).replace(/^\s*|\s*$/g, '');
        return sel.length < 1 || sel == '-1';
    }
    , merge: function merge(sel, defaultSel) {
        var sArr = this.parseToSurfaceArray(sel);
        var dArr = this.parseToSurfaceArray(defaultSel);
        var out = [];
        for (var i = 0, len = sArr.length; i < len; ++i) {
            if (typeof sArr[i] == 'undefined' || sArr[i] == '-1') out.push(dArr[i] || '-1');
            else out.push(sArr[i]);
        }
        return out;
    }
    , asArray: function asArray(sel) {
        sel = String(sel);
        var arr = sel.match(/[0-9|-]+/g);
        return (arr == null) ? [] : arr;
    }
};

Viz.windowSel = {
    gridFrame: 160013
    , createEmpty: function (numerOfTiles) {
        if (numerOfTiles == null) return this.gridFrame + '(4389,-1,-1)';
        var arr = [];
        arr[0] = this.gridFrame;
        while (numerOfTiles--) arr.push('-1');
        //arr.push(1439);
        return this.asSel(arr);
    }
	, asArray: function (sel) {
	    sel = String(sel);
	    var arr = sel.match(/[0-9|-]+/g);
	    return (arr == null) ? [] : arr;
	}
	, asSel: function (arr) {
	    function isArray(obj) { return obj && !(obj.propertyIsEnumerable('length')) && typeof obj === 'object' && typeof obj.length === 'number'; }
	    if (!isArray(arr)) return '';
	    if (arr.length < 1) return '';
	    return sel = arr[0] + '(' + arr.slice(1).join(',') + ')';
	}
	, isEmpty: function (sel) {
	    if (sel == null) return true;
	    sel = String(sel).replace(/^\s*|\s*$/g, '');
	    return sel.length < 1 || sel == '-1';
	}
	, replacePattern: function (sel, patternId, numerOfTiles) {
	    var _ = this;
	    if (_.isEmpty(sel)) sel = _.createEmpty(numerOfTiles);
	    var arr = _.asArray(sel);
	    arr[0] = patternId;
	    return _.asSel(arr);
	}
	, removePattern: function (sel) { return sel.replace(/\d+\(/, '').replace(')', ''); }
    , getPatternId: function (sel) {
        var _ = this;
        if (_.isEmpty(sel)) sel = _.createEmpty(null);
        var arr = _.asArray(sel);
        return arr[0];
    }
	 , getShutter: function (sel) {
	     var _ = this;
	     if (_.isEmpty(sel)) sel = _.createEmpty(null);
	     var arr = _.asArray(sel);
	     return arr[arr.length - 1];
	 }
	, replaceWindow: function (sel, windowId) {
	    var _ = this;
	    if (_.isEmpty(sel)) sel = _.createEmpty(null);
	    var arr = _.asArray(sel);
	    if (arr.length == 1) {
	        arr = _.asArray(_.createEmpty(null));
	    }
	    arr[1] = windowId;
	    return _.asSel(arr);
	}
	, replaceShutter: function (sel, leftShutterId, rightShutterId) {
	    var _ = this;
	    if (_.isEmpty(sel)) sel = _.createEmpty();
	    var arr = _.asArray(sel);
	    if (arr.length == 1) {
	        var tmp = _.createEmpty();
	        sel = _.replaceWindow(tmp, sel);
	        arr = _.asArray(sel);
	    }
	    if (arr.length != 4) throw new Error('invalid sel = ' + sel);
	    var last = arr.length - 1;
	    arr[last--] = leftShutterId;
	    arr[last] = rightShutterId;

	    return _.asSel(arr);
	}
};


Viz.tileSel = {
    createEmpty: function createEmpty(numerOfTiles) {
        if (numerOfTiles == null) return '148991(-1,1439,147607,-1)';
        var arr = [];
        arr[0] = 1384;
        while (numerOfTiles--) arr.push('-1');
        arr.push(1439);  //grout
        arr.push(147607);  //one-eigth grout width
        arr.push(-1);  //0 degree rotation
        return this.asSel(arr);
    }
  , asArray: function asArray(sel) {
      sel = String(sel);
      var arr = sel.match(/[0-9|-]+/g);
      return (arr == null) ? [] : arr;
  }
  , asSel: function asSel(arr) {
      function isArray(obj) { return obj && !(obj.propertyIsEnumerable('length')) && typeof obj === 'object' && typeof obj.length === 'number'; }
      if (!isArray(arr)) return '';
      if (arr.length < 1) return '';
      return sel = arr[0] + '(' + arr.slice(1).join(',') + ')';
  }
  , isEmpty: function isEmpty(sel) {
      if (sel == null) return true;
      sel = String(sel).replace(/^\s*|\s*$/g, '');
      return sel.length < 1 || sel == '-1';
  }
    , isPattern: function (sel) {
        return (String(sel).indexOf('(') > -1);
    }
  , patternTiles: []
  , replacePattern: function replacePattern(sel, patternId, numerOfTiles, surfaceNum, patternTiles) {
      //replace pattern, and see if we can salvage any of the tiles.. and grout
      var _ = this;
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) {
          sel = _.createEmpty(numerOfTiles);
          var arr = _.asArray(sel);
          arr[0] = patternId;
          if (patternTiles != null) _.patternTiles[surfaceNum] = patternTiles;
          return _.asSel(arr);
      }
      var arr = _.asArray(sel);
      arr[0] = patternId;

      var tmpTiles = _.patternTiles[surfaceNum];
      if (tmpTiles == void 0) tmpTiles = Array(arr.length - 4).join('12x12,').slice(0, -1);
      if (typeof tmpTiles != "undefined" && patternTiles != null) {
          var currentTiles = tmpTiles.split(',');
          var newTiles = patternTiles.split(',');
          var arrNew = arr.slice(0);  //make a copy

          //is the new sel the same size?
          var removeElementFromArray = function (array, from, to) {
              var rest = array.slice((to || from) + 1 || array.length);
              array.length = from < 0 ? array.length + from : from;
              return array.push.apply(array, rest);
          };
          if (currentTiles.length < newTiles.length) { //add elements
              var diff = newTiles.length - currentTiles.length + 1;
              var append = [];
              while (--diff) arrNew.splice(currentTiles.length + 1, 0, '-1');
          }
          else if (currentTiles.length > newTiles.length) {  //remove elements
              var diff = currentTiles.length - newTiles.length;
              removeElementFromArray(arrNew, newTiles.length + 1, newTiles.length + diff);
          }

          //reset tile sels in arrNew
          for (var g = 1, len = arrNew.length - 3; g < len; g++) arrNew[g] = '-1';


          var i = -1, v = newTiles.length;
          while (++i < v) {
              //check if currentTiles has same tile size
              for (var z = 0, len = currentTiles.length; z < len; z++) {
                  if (newTiles[i] == currentTiles[z]) { arrNew[i + 1] = arr[z + 1]; }
              }
          }
          arr = arrNew;
          _.patternTiles[surfaceNum] = patternTiles;
      }
      else _.patternTiles[surfaceNum] = patternTiles;


      return _.asSel(arr);
  }
  , getPatternId: function getPatternId(sel) {
      var _ = this;
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) sel = _.createEmpty(numerOfTiles);
      var arr = _.asArray(sel);
      return arr[0];
  }
  , replaceTile: function replaceTile(sel, tileId, index) {
      var _ = this;
      if (index == null) throw new Error('index was not provided');
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) sel = _.createEmpty();
      var arr = _.asArray(sel);
      index = parseInt(index);
      if (arr.length <= index + 1 || index < 0) throw new Error('invalid index = ' + index);
      arr[index] = tileId;
      return _.asSel(arr);
  }
  , replaceGrout: function replaceGrout(sel, groutId) {
      var _ = this;
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) sel = _.createEmpty();
      var arr = _.asArray(sel);
      if (arr.length < 4) throw new Error('invalid sel = ' + sel);
      var last = arr.length - 3;
      arr[last] = groutId;
      return _.asSel(arr);
  }
  , getGrout: function getGrout(sel) {
      var _ = this;
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) sel = _.createEmpty();
      var arr = _.asArray(sel);
      return arr[arr.length - 3];
  }
  , replaceGroutWidth: function replaceGroutWidth(sel, widthId) {
      var _ = this;
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) sel = _.createEmpty();
      var arr = _.asArray(sel);
      if (arr.length < 3) throw new Error('invalid sel = ' + sel);
      var last = arr.length - 2;
      arr[last] = widthId;
      return _.asSel(arr);
  }
  , getGroutWidth: function getGroutWidth(sel) {
      var _ = this;
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) sel = _.createEmpty();
      var arr = _.asArray(sel);
      return arr[arr.length - 2];
  }
  , nextRotation: function nextRotation(sel) {
      if (sel == 254259) return { r: 0, sel: '-1' } //254252
      if (sel == 254258) return { r: 315, sel: 254259 }
      if (sel == 254257) return { r: 270, sel: 254258 }
      if (sel == 254256) return { r: 225, sel: 254257 }
      if (sel == 254255) return { r: 180, sel: 254256 }
      if (sel == 254254) return { r: 135, sel: 254255 }
      if (sel == 254253) return { r: 90, sel: 254254 }
      if (sel == 254252) return { r: 45, sel: 254253 }
      if (sel == '-1') return { r: 45, sel: 254253 }
      return { r: 0, sel: '-1' }
  }

  , replaceRotation: function replaceRotation(sel, rotationId) {
      var _ = this;
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) sel = _.createEmpty();
      var arr = _.asArray(sel);
      if (arr.length < 3) throw new Error('invalid sel = ' + sel);
      var last = arr.length - 1;
      arr[last] = rotationId;
      return _.asSel(arr);
  }
  , getRotation: function getRotation(sel) {
      var _ = this;
      if (!_.isPattern(sel)) sel = null;
      if (_.isEmpty(sel)) sel = _.createEmpty();
      var arr = _.asArray(sel);
      return arr[arr.length - 1];
  }





};


Viz.scale = {
    toScale: function (sel) {
        var _sel = parseInt(sel);
        return sel - 360400;
    },
    toSel: function (scale) {
        var _scale = parseInt(scale);
        if (_scale < 1) _scale = 1;
        if (_scale > 400) _scale = 400;
        return 360400 + _scale;
    }
};
