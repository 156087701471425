(function () {
  'use strict';
  var log = debug('cham:email');
  var createComponent = Utilities.createComponent;

  CHAM.email = createComponent({
    constructor: function constructor() {
      var self = this;
      self.modal = $('div#email-modal');
      self.validator = Utilities.lazy(function () {
        return Forms.FormValidator($('#email-modal form'), self.formRules);
      });
      self.events();
      return self.show.bind(self);
    },
    show: function show() {
      var self = this;
      self.modal.show();
      self.createMessage();
    },
    formRules: {
      fromEmail: [{ type: 'required', message: 'Please enter your email address.' }, { type: 'email', message: 'Please enter a valid email.' }],
      toEmail: [{ type: 'required', message: 'Please enter a friend\'s email address.' }, { type: 'email', message: 'Please enter a valid email.' }],
      body: [{ type: 'required', message: 'A message is required.' }]
    },
    createMessage: function (callbackFn) {
      var self = this,
          specifier = CHAM.VIZ.ChamViz({ action: 'test' }).spec,
          selector = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
      CHAM.helpers.getShortURL(specifier, selector, function (url) {
        console.log("short url", url);
        $('textarea').val('Checkout this redesign I did of my house using the ' + CHAM.SiteName + ' visualizer! ' + url);
      });
    },
    events: function () {
      var self = this;

      self.modal.on('click', '.close', function () {
        self.validator.value.resetForm();
        $('#email-modal').hide();
      });
      self.modal.on('submit', function (e) {
        e.preventDefault();
        var validator = self.validator.value;
        if (!validator.validateAndPrintMessages())
          return;

        $.post('/services/email.ashx', validator.gatherFormValues());
          validator.resetForm();
          $('#email-modal').hide();
      });
    }
  });
})();
