(function () {
  'use strict';

  var log = debug('cham:social');

  CHAM.share = {
    config: {
      fbAppID: CHAM.FbAppID,
      imperURL: CHAM.CurrentDomain,
      title: 'Checkout this design I created for my house using the ' + CHAM.SiteName + ' visualizer! ',
      siteName: CHAM.SiteName,
      siteid: CHAM.SiteID,
      sel: '',
      spec: '',
      vizSelector: '#visualizer',
      hasGPlus: false,
      viz: null,
      FBLinkSelector: '.facebookLinkk',
      TWLinkSelector: '.twitterLinkk',
      PTLinkSelector: '.pinterestLinkk',
      GPlusLinkSelector: '#googleplusLinkk',
      SocialBTNLoc: '#share',
      target: '_blank'
    },
    init: function init() {
      this.social();
      this.initComplete = true;
    },
    initComplete: false,
    social: function social() {
      socialMedia.init(CHAM.share.config);
    },
    events: function () {
      $(".share-link").on("click", function () {
        CHAM.helpers.getShortURL(CHAM.DATA.specifier, CHAM.VIZ.ChamViz({ action: 'getFullSelString' }), function (url) {
          $("#shareURL").val(url);
          CHAM.integration.logModalOpen("copy-url");
          $("#copy-modal").show();
          CHAM.integration.scrollParentToTop();
        });
      });

      $(".twitter-link").on("click", function () {
        CHAM.helpers.getShortURL(CHAM.DATA.specifier, CHAM.VIZ.ChamViz({ action: 'getFullSelString' }), function (shortUrl) {
          window.open("https://twitter.com/intent/tweet?text=" + encodeURIComponent(CHAM.share.config.title) + " " + shortUrl);
        });
      });

      $(".pinterestLink").on("click", function () {
        var spec = CHAM.DATA.specifier;
        var sel = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
        CHAM.helpers.getShortURL(spec, sel, function (shortUrl) {
          window.open("https://pinterest.com/pin/create/button/?url=" + encodeURIComponent(shortUrl) +
            "&description=" + encodeURIComponent(CHAM.share.config.title) +
            "&media=" + encodeURIComponent(
              'https://core2render.chameleonpower.com/cham/GetImage.ashx?spec=' + spec + '(focus=300)&sel=' + sel + ' &Size=512&type=base'));
        });
      });

      $(".facebook-link").on("click", function () {
        console.log('clicked here');
        FB.ui(
          {
            method: 'share',
            href: $(".facebookLink").attr("href"),

          }, function (response) { });
      });

      $("#copy-modal .copyToClipboard").on("click", function () {
        $("#copy-modal #shareURL").select();

        try {
          document.execCommand('copy');
          alertify.alert("URL copied");
          CHAM.integration.scrollParentToTop();
        }
        catch (err) {
          alertify.alert('Unable to copy to clipboard, your browser may not support this feature. You will have to copy this link manually.');
        }
      });

      $("#copy-modal .close, #copy-modal .cancel").on("click", function () {
        $("#copy-modal").fadeOut();
      });
    },
    update: function update() {
      if (!this.initComplete) {
        this.init();
      }

      var ogLink = "https://brampton.chameleonpower.com/og_meta.aspx?Specifier=" +
        CHAM.VIZ.ChamViz({ action: 'test' }).spec.replace(/\\/g, "%5C") +
        "~" +
        CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
      //var imageLink = "https://brampton.chameleonpower.com/og_pic.ashx";

      var fbURL = "https://www.facebook.com/dialog/feed?app_id=" +
        CHAM.FbAppID +
        "&link=" +
        ogLink +
        "&redirect_uri=" +
        ogLink //+
      //"&picture=" +
      //imageLink;
      //console.log("fbURL: " + fbURL)
      ;
      $(".facebookLink").attr("href", fbURL);


      $(".twitterLink").off('click').on("click", function () {
        var windowReference = window.open();
        var spec = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
        var sel = CHAM.VIZ.ChamViz({ action: 'getFullSelString' })
        CHAM.helpers.getShortURL(spec, sel, function (shortUrl) {
          console.log("short url", shortUrl);
          windowReference.location = ("https://twitter.com/intent/tweet?text=" + encodeURIComponent(CHAM.share.config.title) + " " + shortUrl);
        });
      });

      $(".pinterestLink").off('click').on("click", function () {
        var spec = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
        var sel = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
        var windowReference = window.open();
        CHAM.helpers.getShortURL(spec, sel, function (shortUrl) {
          windowReference.location = ("https://pinterest.com/pin/create/button/?url=" + encodeURIComponent(shortUrl) +
            "&description=" + encodeURIComponent(CHAM.share.config.title) +
            "&media=" + encodeURIComponent(
              'https://core2render.chameleonpower.com/cham/GetImage.ashx?spec=' + spec + '(focus=300)&sel=' + sel + ' &Size=512&type=base'));
        });
      });

    }
  };
})();
