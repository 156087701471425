'use strict';

var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {

  var log = debug('cham:config');

  CHAM.SiteID = 'Brampton';// Set in Master Page
  CHAM.SiteName = 'Brampton';
  CHAM.GoogleAnalytics = 'UA-8529778-3';  //chameleon == UA-8529778-3
  CHAM.FbAppID = '920595084765268';
  CHAM.HighlightColor = 'ED1B24';

  CHAM.DATA.series = [
    //{ type: '[TYPE]', id: 12345 }
    { type: 'brick', id: 6448 },
    { type: 'mortar', id: 4809 },
    { type: 'trim', id: 204 },
    { type: 'roof', id: 6285 }
  ];

  CHAM.RenderServer = '//core2render.chameleonpower.com/cham/';
  CHAM.CurrentDomain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

  CHAM.CookieMessage = '<strong>Please Enable Cookies</strong><br /><br /> \
    Your browser\'s cookie functionality is turned off. Please turn it on to use this site.<br /><br /> \
    <em>If you are using Safari, make sure 3rd party cookies are enabled.</em> \
  ';

  if (CHAM.SiteID === '[SITEID]') console.warn('INVALID SITE ID: Make sure to update your configuration(s)');

})(jQuery);
