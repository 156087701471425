(function () {
  'use strict';

  var log = debug('cham:projects');

  CHAM.projects = {
    apiRoot: '/services/jsonproxy.ashx?remoteURL=https://lizard.chameleonpower.com/',
    //apiRoot: '/services/jsonproxy.ashx?remoteURL=http://localhost:26820/',
    rootSelector: '#my-projects', // this is where modals will be inserted
    loggedIn: function () {
      /* Technically this returns true if the user is
            logged in (has a session token) or is
            or is ATTEMPTING to log in (has a password).
            The password is only stored for the few milliseconds
            it takes to generate the LoginDTO
        */
      return (this.sessionToken && this.sessionToken.length == 64) || this.password;
    },
    email: '',
    sessionToken: '',
    nextDialog: '', // projects,submitSave,upload,changepassword
    projectList: [],

    getLoginDTO: function () {
      var dto = { email: this.email, sessionToken: this.sessionToken, siteID: CHAM.SiteID };

      if (this.password) {
        dto.sessionToken = '';
        dto.password = this.password;
        this.password = null;
      }

      return dto;
    },

    init: function () {
      // Read email, session token from cookies
      var email = Cookies.get('email');
      if (email) {
        this.email = email.trim();
      }

      var sessionToken = Cookies.get('SessionToken');
      if (sessionToken) {
        this.sessionToken = sessionToken;
      }

      this.events();
    },
    events: function () {
      // Menu events
      var header = $('nav.visualizer');

      header.off('click', 'div#projects, div#sign-in').on('click', 'div#projects, div#sign-in', function () {
        CHAM.projects.showProjects();
      });

      $("#save").off('click').on('click', function () {
        CHAM.projects.showSave();
      });

      /*
            Bind all modal events to the root element, because sub elements will be added and removed
            on every modal load/close, therefore cannot persist event bindings.
        */

      // Remove all events attached to the root selector to prevent double/triple binding.
      $(CHAM.projects.rootSelector).off();

      // Close button
      $(CHAM.projects.rootSelector).on('click', '.closeBTN', function () {
        CHAM.projects.closeModal();
      });

      $('#closemodal').off('click').on('click', function () {
        CHAM.projects.nextDialog = '';
      });

      // Title bar logout link
      $(CHAM.projects.rootSelector).on('click', '.logoutBTN', function () {
        CHAM.projects.logout();
        CHAM.projects.showLogin();
      });

      // Title bar change password button 
      $(CHAM.projects.rootSelector).on('click', '.goToChangeBTN', function () {
        // Are we logged in?
        if (CHAM.projects.loggedIn()) {
          CHAM.projects.showChangePassword();
          return;
        }

        CHAM.projects.nextDialog = 'changepassword';
        CHAM.projects.showLogin();
      });

      // Register link 
      $(CHAM.projects.rootSelector).on('click', '.gotoRegBTN', function () {
        CHAM.projects.showRegister();
      });

      // Reset password link
      $(CHAM.projects.rootSelector).on('click', '.gotoForgotBTN', function () {
        CHAM.projects.showResetPassword();
      });

      // "I remember my password" link
      $(CHAM.projects.rootSelector).on('click', '.gotoLoginBTN', function () {
        CHAM.projects.showLogin();
      });

      // Login submit
      $(CHAM.projects.rootSelector).on('click', '.loginBTN', function () {
        CHAM.projects.submitLogin();
        //CHAM.projects.closeModal();
      });

      // Enter key while in any input field should submit that form
      $(CHAM.projects.rootSelector).on('keypress', 'input', function (e) {
        // This used to find the div.Button and fire its click event, but that had side effects 
        // that were hard to track down. So now we are just figuring out which form were are in,
        // and calling the appropriate submit function.
        var keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode == '13') {
          // Which form are we on?
          if ($(".Content .loginForm").length > 0) { CHAM.projects.submitLogin(); return; }
          if ($(".Content .regForm").length > 0) { CHAM.projects.submitRegister(); return; }
          if ($(".Content .forgotForm").length > 0) { CHAM.projects.submitResetPassword(); return; }
          if ($(".Content .saveForm").length > 0) { CHAM.projects.submitSave(); return; }
          if ($(".Content .changeForm").length > 0) { CHAM.projects.submitChangePassword(); return; }
        }
      });

      // Register submit button
      $(CHAM.projects.rootSelector).on('click', '.regBTN', function () {
        CHAM.projects.submitRegister();
      });

      // Reset password submit button
      $(CHAM.projects.rootSelector).on('click', '.forgotBTN', function () {
        CHAM.projects.submitResetPassword();
      });

      // Change password link
      $(CHAM.projects.rootSelector).on('click', '.goToChangeBTN', function () {
        CHAM.projects.showChangePassword();
      });

      // Change password submit button
      $(CHAM.projects.rootSelector).on('click', '.changePwdBTN', function () {
        CHAM.projects.submitChangePassword();
      });

      // Save project submit button
      $(CHAM.projects.rootSelector).on('click', '.saveBTN', function () {
        CHAM.projects.submitSave();
      });

      // View Project button
      $(CHAM.projects.rootSelector).on('click', '.Use', function () {
        var id = $(this).data("id");
        CHAM.projects.loadProject(id);
      });

      // Edit Project button
      $(CHAM.projects.rootSelector).on('click', '.Edit', function () {
        var specifier = $(this).data("specifier");
        window.location.href = "/IM.aspx?specifier=" + specifier;
      });

      // Remove project button
      $(CHAM.projects.rootSelector).on('click', '.Remove', function () {
        var id = $(this).data("id");
        CHAM.projects.deleteProject(id);
      });
    },
    showProjects: function () {
      if (!this.loggedIn()) {
        this.nextDialog = 'projects';
        this.showLogin();
        return;
      }

      $.post(CHAM.projects.apiRoot + "Project/GetProjects",
        JSON.stringify(CHAM.projects.getLoginDTO()),
        function (data) {
          if (data.success) {
            CHAM.projects.sessionToken = data.sessionToken;
            Cookies.set('SessionToken', data.sessionToken, { expires: 30 });
            CHAM.projects.projectList = data.data;

            switch (CHAM.projects.nextDialog) {
              case 'upload':
                CHAM.upload.showImageMapper();
                CHAM.projects.closeModal();
                break;
              default: // projects
                var content = CHAM.templates.projects.projectModal(CHAM.projects.projectList);
                CHAM.projects.showDialog('MY PROJECTS', '<div class="buttons goToChangeBTN">Change Password?</div><div class="buttons logoutBTN">Log Out</div>',
                    content);
                if (CHAM.projects.projectList.length == 0) {
                  $(".projectsForm").html("No items have been saved");
                }
                break;
            }
          }
          else {
            CHAM.projects.showLogin();
            $('.errors').hide().html('Check your login credentials and try again').fadeIn('slow');
          }
        },
        "json")
        .fail(function () {
          CHAM.projects.showLogin();
          $('.errors').hide().html('An unknown error occured.').fadeIn('slow');
        });
    },
    showLogin: function (callback) {
      // ToDo: check if already logged in and redirect to projects list?
      var loginModalContent = CHAM.templates.projects.loginModal(this.email);
      this.showDialog('Login', '', loginModalContent);
    },
    showRegister: function () {
      var regModalContent = CHAM.templates.projects.registerModal(this.email);
      this.showDialog('Register', '', regModalContent);
    },
    showDialog: function (title, titleBarBTNS, content) {
      var modal = CHAM.templates.projects.modal(title, titleBarBTNS, content);
      $(this.rootSelector).html(modal);
      $(this.rootSelector).fadeIn();
    },
    logout: function () {
      CHAM.projects.sessionToken = '';
      Cookies.set('SessionToken', '', { expires: -1 });
    },
    closeModal: function () {
      $(CHAM.projects.rootSelector).fadeOut().promise().then(
            function () {
              $(this).empty();
            }
        );
    },
    submitLogin: function () {
      // Read inputs, save them to object state
      if (CHAM.projects.validateEmail($("#loginEmail").val())) {
        if (!$('input#loginPassword').val()) $('div.errors').html('You must enter a password to log in.').show();
        else {
          this.email = $("#loginEmail").val();
          this.password = $("#loginPassword").val();
          Cookies.set('email', this.email, { expires: 365 });
          $(CHAM.projects.rootSelector).fadeOut();
          this.showProjects();
        }
      }
    },
    validateEmail: function (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return (true);
      }
      $('div.errors').html('You must enter a valid email address.').show();
      return (false);
    },
    submitRegister: function () {
      if (CHAM.projects.validateEmail($("#RegEmail").val())) {

        var postData = {
          email: $("#RegEmail").val(),
          password: $("#RegPassword").val(),
          firstName: $("#RegFName").val(),
          lastName: $("#RegLName").val(),
          siteID: CHAM.SiteID
        };

        CHAM.projects.email = postData.email;

        $.post(CHAM.projects.apiRoot + "User/Register", JSON.stringify(postData),
          function (data) {
            if (data.success) {
              CHAM.projects.sessionToken = data.sessionToken;
              // were we in the middle of saving when we registered?
              if (CHAM.projects.tmpProjectName) {
                CHAM.projects.submitSave();
                return;
              }
              if (CHAM.projects.nextDialog === 'upload') {
                $(CHAM.projects.rootSelector).fadeOut();
                CHAM.upload.showImageMapper();
              }
              // else just close modal
              $(CHAM.projects.rootSelector).fadeOut();
            }
            else {
              $('.errors').hide().html(data.message).fadeIn('slow');
            }
          },
          "json")
          .fail(function () {
            $('.errors').hide().html('An unknown error occured.').fadeIn('slow');
          });
      }
    },
    showSave: function () {
      var name = this.tmpProjectName ? this.tmpProjectName : '';
      var content = CHAM.templates.projects.saveProjectModal(name);
      this.showDialog('Save Current Project', '', content);
    },
    showChangePassword: function () {
      var content = CHAM.templates.projects.changePasswordModal(this.email);
      this.showDialog('Change Password', '', content);
    },
    submitChangePassword: function () {
      // New and confirm new must match
      var newPass = $("#NewPassword").val();
      var confirmNewPass = $("#ConfirmNewPassword").val();
      if (newPass != confirmNewPass) {
        $(".errors").hide().html("New password and confirm new password must match.").fadeIn('slow');
        return;
      }

      var oldPass = $("#OldPassword").val();
      if (oldPass.length < 1) {
        $(".errors").hide().html("Current password cannot be blank.").fadeIn('slow');
        return;
      }

      // Set the current password,
      // this tells the getLoginDTO function to remove the session token and re-verify current password
      this.password = oldPass;

      var postData = { login: this.getLoginDTO(), newPassword: newPass };

      $.post(CHAM.projects.apiRoot + "User/ChangePassword", JSON.stringify(postData),
        function (data) {
          if (data.success) {
            // Should be a new session token
            CHAM.projects.sessionToken = data.sessionToken;
            CHAM.projects.showProjects();
          }
          else {
            $('.errors').hide().html(data.message).fadeIn('slow');
          }
        },
        "json")
        .fail(function () {
          $('.errors').hide().html('An unknown error occured.').fadeIn('slow');
        });
    },
    showResetPassword: function () {
      var content = CHAM.templates.projects.resetPasswordModal(this.email);
      this.showDialog('Reset Password', '', content);
    },
    submitResetPassword: function () {
      var postData = { email: $("#txtForgotEmail").val(), siteID: CHAM.SiteID };

      $.post(CHAM.projects.apiRoot + "User/ResetPassword", JSON.stringify(postData),
        function (data) {
          if (data.success) {
            $('.success').hide().html("An email should arrive shortly.<br/>").fadeIn('slow');
          }
          else {
            $('.errors').hide().html(data.message).fadeIn('slow');
          }
        },
        "json")
        .fail(function () {
          $('.errors').hide().html('An unknown error occured.').fadeIn('slow');
        });
    },
    submitSave: function () {
      // Store the name in case we have to go log in /register before continuing
      this.tmpProjectName = this.tmpProjectName ? this.tmpProjectName : $("#SaveProjectName").val();
      // Must not be empty
      if (!this.tmpProjectName || this.tmpProjectName.length < 1) {
        $('.errors').hide().html('Name is required').fadeIn('slow');
        return;
      }

      if (!this.loggedIn()) {
        this.nextDialog = 'submitSave';
        this.showLogin();
        return;
      }

      // This is what will be stored as the project info / Must be customized if stores are involved
      var sel = CHAM.selStore.getState();
      var router = CHAM.router._lastRouteResolved;
      var routeData = CHAM.VIZ.ChamViz({ action: 'test' });
      var projectData =
      {
        Name: this.tmpProjectName,
        Params:
        {
          specifier: routeData.spec,
          sel: CHAM.selStore.getState(),
          url: router.url
        }
      };

      var postData = { login: this.getLoginDTO(), project: projectData };

      $.post(CHAM.projects.apiRoot + "Project/Save", JSON.stringify(postData),
      function (data) {
        if (data.success) {
          // Show a success message or redirect to projects or login?
          CHAM.projects.showProjects();
        }
        else {
          $('.errors').hide().html(data.message).fadeIn('slow');
        }
      },
      "json")
      .fail(function () {
        $('.errors').hide().html('An unknown error occured.').fadeIn('slow');
      });
    },
    deleteProject: function (id) {
      var postData = { login: this.getLoginDTO(), projectID: id };

      $.post(CHAM.projects.apiRoot + "Project/Delete", JSON.stringify(postData),
        function (data) {
          if (data.success) {
            // Show a success message or redirect to projects or login?
            CHAM.projects.showProjects();
          }
          else {
            $('.errors').hide().html(data.message).fadeIn('slow');
          }
        },
        "json")
        .fail(function () {
          $('.errors').hide().html('An unknown error occured.').fadeIn('slow');
        });
    },
    loadProject: function (id) {
      var self = this;
      var proj = _.find(this.projectList, function (p) { return p.id == id });
      if (proj) {
        var lastRoute = (CHAM.router.lastRouteResolved());
        if (proj.params.url != lastRoute.url || lastRoute.url == null) {

          CHAM.store.reset(CHAM.stores.init);
          window.location.replace('/#/scene/' + proj.params.specifier + '/products/' + proj.params.sel);
          window.location.reload(true);
        }
        this.closeModal();
      }
      else {
        alert("Unknown error, please reload your project list.");
      }
    }
  };
})();
