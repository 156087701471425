(function () {
  'use strict';

  var log = debug('cham:templates');

  CHAM.templates = {
    core:  {
      landing: function landing(scenes) {
        return CHAM.compiled['core/landing']({ scenes: scenes });
      },
      visualizer: function visualizer() {
        return CHAM.compiled['core/visualizer']();
      }
    },
    menu:  {
      brickStone: function brickStone(products, surfaces, state) {
        return CHAM.compiled['menu/brick_stone']({ products: products, surfaces: surfaces, state: state });
      },
      trim: function trim(products, surfaces, state) {
        return CHAM.compiled['menu/trim']({ products: products, surfaces: surfaces, state: state });
      },
      roof: function roof(products, state, surfaces) {
        return CHAM.compiled['menu/roof']({ products: products, state: state, surfaces: surfaces });
      },
    },
    header: function (){
      return CHAM.compiled['header']();
    },
    projects: {
      modal: function (title, titleBarBTNS, content) {
        return CHAM.compiled['modal']({ model: { title: title, titleBarBTNS: titleBarBTNS, content: content } });
      },
      loginModal: function (email) {
        return CHAM.compiled['projects/login_dialog']({ model: { email: email } });
      },
      projectModal: function (projects) {
        return CHAM.compiled['projects/projects_dialog']({ model: { projects: projects } });
      },
      registerModal: function (email) {
        return CHAM.compiled['projects/register_dialog']({ model: { email: email } });
      },
      saveProjectModal: function (name) {
        return CHAM.compiled['projects/saveProject_dialog']({ model: { name: name } });
      },
      changePasswordModal: function () {
        return CHAM.compiled['projects/changePassword_dialog']();
      },
      resetPasswordModal: function (email) {
        return CHAM.compiled['projects/resetPassword_dialog']({ model: { email: email } });
      }
    },
    calculator: function calculator () {
      return CHAM.compiled['calculator']();
    },
    calculatorResult: function calculator(result) {
      return CHAM.compiled['calculatorResult']({ result: result });
    }
  };
})();
