(function () {

  'use strict';
  var log = debug('cham:products');
  var createComponent = Utilities.createComponent;

  CHAM.products = createComponent({
    constructor: function constructor() {
      var self = this;
      var root = document.querySelector('main');
      self.data();
      return this.render.bind(this, root);
    },
    data: function data() {
      return new Promise(function (resolve) {
        CHAM.services.getProductsBySeriesIDs(_.map(CHAM.DATA.series, function (series) { return series.id; })).then(function (products) {
          products = _.groupBy(products, function (product) { return 's' + product.seriesID; });
          resolve(CHAM.DATA.products = products);
        });
      });
    },
    render: function render() {
      var self = this;
      self.data().then(function (products) {
        log('Render Products', products);
        CHAM.menu.brickStone().render();
        CHAM.menu.trim().render();
        CHAM.menu.roof().render(); 
      });
    }
  });
})();
