(function() {
  'use strict';

  var log = debug('cham:init'),
      $body = $('body');

  CHAM.initialize = {
    bootstrap: function bootstrap() {
      CHAM.debug.init();
      this.cookie();
      $('body').addClass(Utilities.isSmallMobileDevice() ? 'mobile' : 'non-mobile');
      CHAM.helpers.polyfills();
      this.cookie();
      this.stores();
      this.selStores();
      this.router();
      CHAM.helpers.initShareLink();
    },
    cookie: function cookie() {
      Cookies.set('SiteID', CHAM.SiteID);
      Cookies.set('SiteId', CHAM.SiteID);
      if (Cookies.get('SiteID') === void 0 || Cookies.get('SiteId') === void 0 ) {
        alertify.alert(CHAM.CookieMessage);
      }
    },
    stores: function stores() {
      CHAM.store = CHAM.stores.create();
    },
    selStores: function selStores() {
      CHAM.selStore = CHAM.selStores.create();
    },
    router: function router() {
      var self = this;
      CHAM.router = new Navigo(null, true);
      var root = document.querySelector('main');
      CHAM.router.on({
        'scene/:spec/products/:sel': function (params) {
          self.routeChange(self, root, params);
        },
        '*': function () {
          if ($('div.visualizer-wrapper').length > 0) {
            $('div.visualizer-wrapper').remove();
          }
          CHAM.core.scenes(root).render();
          if ((CHAM.router.lastRouteResolved()).query == 'upload=1') {
            CHAM.upload.init();
          }
        }
      }).resolve();
    },
    routeChange: function routeChange(self, root, params) {
      if (!CHAM.VIZ || !CHAM.VIZ.ChamViz({ action: 'test' })) {
        var main = document.getElementsByTagName('main')[0];
        main.innerHTML = '';
        CHAM.projects.init();
        CHAM.core.visualizer(main).render();
        CHAM.visualizer.init(params.spec, params.sel);
      } else {
        var lastSpec = (CHAM.VIZ.ChamViz({ action: 'test' })).spec;
        if (lastSpec != params.spec) {
          CHAM.visualizer.init(params.spec, params.sel);
        }
      }
    },
    surfacesLoaded: function surfacesLoaded(surfaces) {
      // Called in visualizer.js
      var root = document.querySelector('div#menuProductTemplates');
      var div = document.createElement('div');
      var addSeriesIdSurface = _.map(surfaces, function (o) {
        if (o.type === 'Wall') {
          o.seriesid === "" ? o.seriesid = '6448' : o.seriesid;
        }
        if (o.type === 'Roof') {
          o.seriesid === "" ? o.seriesid = '6285' : o.seriesid;
        }
        else if (o.type !== 'Roof' && o.type !== 'Wall') {
          o.seriesid === "" ? o.seriesid = '204' : o.seriesid;
        }
        return o;
      });
      CHAM.DATA.surfaces = surfaces;
      
      if (_.find(CHAM.DATA.surfaces, ['type', 'Roof'])) $('li#roof-toggle').show();
      if (_.find(CHAM.DATA.surfaces, ['seriesid', '204'])) $('li#trim-toggle').show();
      if (_.find(CHAM.DATA.surfaces, ['type', 'Wall'])) $('li#brick-toggle').show();
      CHAM.stores.populateSurfaces(CHAM.DATA.surfaces);
      if ((CHAM.router.lastRouteResolved()).params.sel != CHAM.selStore.getState()) CHAM.selStore.reset((CHAM.router.lastRouteResolved()).params.sel);
      if (CHAM.selStore.getState() != '-1') CHAM.stores.generateStateFromSel(CHAM.DATA.surfaces);
      CHAM.products().render();
    },
    AllSurfacesLoaded: function AllSurfacesLoaded() {
      CHAM.share.update();
      window.setTimeout(function () {
        LoadingMsg.stop('loadViz');
      }, 2000);
    },
    initComplete: function () {
      // Called in visualizer.js
      CHAM.share.init();
    },
  };
  if ($('body.page-default').length > 0) {
    log(' %c⚡ %cChameleon Powered On', CHAM.debug.color.yellow, '');
    $(document).ready(CHAM.initialize.bootstrap());
  }
})();
