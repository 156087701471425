(function () {

  'use strict';
  var log = debug('cham:scenes');
  var createComponent = Utilities.createComponent;
  // landing
  //visualizer createComponent

  CHAM.core = {
      scenes: createComponent({
        constructor: function constructor(root) {
          var div = root.appendChild(document.createElement('section'));
          div.className = 'scenes';
          CHAM.projects.init();
          this.events($(root));
          return this.render.bind(this, root, div);
        },
        data: function data() { 
          return new Promise(function (resolve) {
            if (CHAM.DATA.scenes) resolve(CHAM.DATA.scenes);
            CHAM.services.getScenesBySeriesID(6442).then(function (scenes) {
              resolve(CHAM.DATA.scenes = scenes);
            });
          });
        },
        render: function render(root, div) {
          var self = this;
          self.data().then(function (scenes) {
            div.innerHTML = CHAM.templates.core.landing(scenes);
          });
        },
        events: function events(root) {
          var self = this;
          root.off('click', '#roomscene-data li').on('click', '#roomscene-data li', function () {
            CHAM.selStore.reset('');
            var specifier = $(this).data('specifier');
            $('section.scenes').remove();
            CHAM.router.navigate('/scene/' + specifier + '/products/-1');
          });
          root.off('click', '#landing-projects').on('click', '#landing-projects', function () {
            CHAM.projects.showProjects();
          });
          root.off('click', 'div#upload-link').on('click', 'div#upload-link', function () {
            CHAM.upload.init();
          });
        }
      }),
      visualizer: createComponent({
        constructor: function constructor(root) {
          var div = root.appendChild(document.createElement('div'));
          div.className = 'visualizer-wrapper';
          this.events($(root));
          this.applyProduct();
          return this.render.bind(this, root, div);
        },
        render: function render(root, div) {
          div.innerHTML = CHAM.templates.core.visualizer();
          CHAM.header().render();
        },
        applyProduct: function applyProduct() {
          CHAM.selStore.subscribe(function (state) {
            if (CHAM.VIZ && state !== '' && state != null) {
    
              CHAM.visualizer.applyFullSel(state, '');
              var spec = (CHAM.VIZ.ChamViz({ action: 'test' })).spec;
              CHAM.router.navigate('/scene/' + spec + '/products/' + state);
              
            }
          });
        },
        events: function events(root) {
          var self = this;
          var main = $('section#MAIN');
          main.off('click', 'nav.visualizer, div#calc-button, div.footer').on('click', 'nav.visualizer, div#calc-button, div.footer', function () {
            $('div#menuProductTemplates').children().removeClass('active');
            $('div.surface-menu ul').children().removeClass('active');
          });
          root.off('click', 'button#zoom').on('click', 'button#zoom', function () {
            if (!$(this).hasClass('zoomed')) {
              $(this).addClass('zoomed');
              CHAM.VIZ.ChamViz({ action: 'zoom' });
            };
          });
          root.off('click', 'button#unzoom').on('click', 'button#unzoom', function () {
            if ($('button#zoom').hasClass('zoomed')) {
              CHAM.VIZ.ChamViz({ action: 'unzoom' });
              $('button#zoom').removeClass();
            };
          });
          root.off('click', 'button#undo').on('click', 'button#undo', function () {
            CHAM.selStore.undo();
            CHAM.stores.generateStateFromSel(CHAM.DATA.surfaces);
          });
          root.off('click', 'button#redo').on('click', 'button#redo', function () {
            CHAM.selStore.redo();
            CHAM.stores.generateStateFromSel(CHAM.DATA.surfaces);
          });
          root.off('click', 'div#email-btn').on('click', 'div#email-btn', function () {
            CHAM.email().render();
            CHAM.stores.generateStateFromSel(CHAM.DATA.surfaces);
          });
          root.off('click', 'div#email-btn').on('click', 'div#email-btn', function () {
            CHAM.email().render();
            CHAM.stores.generateStateFromSel(CHAM.DATA.surfaces);
          });
          root.off('click', 'div#print').on('click', 'div#print', function () {
            var specifier = CHAM.VIZ.ChamViz({ action: 'test' }).spec;
            var selector = CHAM.VIZ.ChamViz({ action: 'getFullSelString' });
            var url = CHAM.CurrentDomain
              + '/print.aspx?specifier=' + encodeURIComponent(specifier)
              + (selector != null && selector !== '' ? '&sel=' + encodeURIComponent(selector) : '');
            window.open(url);
          });

          //menu test
          root.off('click', 'div.surface-menu li').on('click', 'div.surface-menu li', function () {
            CHAM.visualizer.events();
            var type = $(this).data('type');
            CHAM.stores.events.resetSurfaces.emit('All');
            $('div.side-surface li').removeClass('active');
            $('div.side-surface ul[data-type="' + type + '"] li:first-child').addClass('active');
            $(this).toggleClass('active');
            $(this).siblings().removeClass('active');
            if (!$('div#menuProductTemplates').find('div[data-type="' + type + '"]').hasClass('active')) {
              $('div#menuProductTemplates').children('div').removeClass();
              $('div#menuProductTemplates').find('div[data-type="' + type + '"]').addClass('active');
            } else {
              $('div#menuProductTemplates').children('div').removeClass();
            }
            var state = CHAM.store.getState();
            var surfaces = state.surfacesSelected;
            var brickStone = state.BrickStone;
            if (type === 'brick') {
              $('div#brick-menu div.product-list li').removeClass('active');
              if ($('div.brick-products').hasClass('active')) {
                $('li#brick-button').addClass('active')
              } else if ($('div.stone-products').hasClass('active')) {
                $('li#stone-button').addClass('active');
              } else if ($('div.mortar-products').hasClass('active')) {
                $('li#mortar-button').addClass('active');
              }
              var state = CHAM.store.getState();

              setTimeout(function () {
                var idArray = [];
                if (state.surfacesSelected[0] == 'All') {
                  if ($('div.mortar-products').hasClass('active')) {
                    //open menu again after closing menu button Mortar
                    _.each(brickStone, function (value, key) {
                      if (value === '' || value === '-1') {
                        $('div.mortar-products li.mortar').addClass('hide');
                        $('div.mortar-products li.mortar-msg').addClass('active');
                      } else {
                        var idArray = [];
                        for (var k in state.BrickStone) {
                          idArray.push(state.BrickStone[k]);
                        }
                        if (_.uniqBy(idArray).length > 1 ) {
                          $('div.mortar-products li.mortar').addClass('hide');
                          $('div.mortar-products li.mortar-msg').addClass('active');
                        } else {
                          $('div.mortar-products li.mortar').removeClass('hide');
                          $('div.mortar-products li.mortar-msg').removeClass('active');
                          if ($('div.mortar-products').hasClass('active')) {
                            _.each(state.Mortar, function (value, key) {
                              $('div.mortar-products li.mortar[data-id="' + value + '"]').addClass('active');
                              if ($('div.mortar-products li.mortar').hasClass('active') && value != '') {
                                $('div.mortar-products li.mortar[data-id="' + value + '"]').get(0).scrollIntoView({ block: "nearest" });
                              }
                            });
                          }
                          var values = Object.keys(state.BrickStone).map(function (e) {
                            return state.BrickStone[e];
                          });
                          $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').addClass('active');
                          if ($('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').hasClass('active')) {
                            $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').get(0).scrollIntoView({ block: "nearest" });
                          }
                          
                        }
                      }
                    })
                  } else {
                    //opens brick stone menu at and checks All
                      for (var k in state.BrickStone) {
                        idArray.push(state.BrickStone[k]);
                      }
                      if (_.uniqBy(idArray).length > 1) {
                        $('div.mortar-products li.mortar').addClass('hide');
                        $('div.mortar-products li.mortar-msg').addClass('active');
                      } else {
                        //returns value of first key in object.
                        var values = Object.keys(state.BrickStone).map(function (e) {
                          return state.BrickStone[e];
                        });
                        console.log(values[0]);
                       
                         if (values[0]) {
                           $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').addClass('active');
                          if ($('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').hasClass('active')) {
                            $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').get(0).scrollIntoView({ block: "nearest" });
                            }
                         }
                      }
                  } 
                } 
              });
            } else {
              //trim and roof menus
              var state = CHAM.store.getState();
              $('div.product-menu li').removeClass('active');
             
              setTimeout(function () {
                var idArray = [];
                if (state.surfacesSelected[0] == 'All') {
                  for (var k in state[_.capitalize(type)]) {
                    idArray.push(state[_.capitalize(type)][k]);
                  }
                  if (_.uniqBy(idArray).length > 1) {
                    //console.log('nothing matches');
                  } else {
                    var values = Object.keys(state[_.capitalize(type)]).map(function (e) {
                      return state[_.capitalize(type)][e];
                    });
                    console.log(values[0]);
                    if (values[0]) {
                      $('div.product-menu li[data-id="' + values[0] + '"]').addClass('active');
                      if ($('div.product-menu li[data-id="' + values[0] + '"]').hasClass('active')) {
                        $('div.product-menu li[data-id="' + values[0] + '"]').get(0).scrollIntoView({ block: "nearest" });
                      }
                    }
                  }
                }
              });
            }
          });
        }
      })
  }
})();
