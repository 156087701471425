(function () {
  'use strict';

  var log = debug('cham:upload');

  CHAM.upload = {
    init: function () {
      var self = this;
      this.projects = $('#my-projects');
      this.mapper = $('#uploadOverlay, #upload-container');
      this.events();
      if (CHAM.projects.loggedIn()) {
        self.showImageMapper();
      }
      else {
        CHAM.projects.nextDialog = 'upload';
        CHAM.projects.showLogin();
      }
    },
    showImageMapper: function () {
      this.mapper.fadeIn('slow');
    },
    events: function () {
      var self = this;
      var projects = this.projects;
      //console.log('upload eventttsssss');
      $('div#close-upload').off('click').on('click', function () {
        $('div#upload-container').fadeOut();
      });
    }
  };
})();
