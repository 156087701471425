var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var log = debug('cham:applied');

  CHAM.applied = {
    init: function init() {

    },
  };
})(jQuery);
