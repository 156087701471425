var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var log = debug('cham:data');

  CHAM.DATA = {
    products: {}
  };

  CHAM.services = {

    lizard: '/services/jsonProxy.ashx?remoteURL=https://lizard.chameleonpower.com/',

    getScenesBySiteID: function getScenesBySiteID() {
      return fetch(this.lizard + 'RoomScene/SiteID/' + CHAM.SiteID).then(function (response) {
        return response.json();
      });
    },
    getScenesBySeriesID: function getScenesBySeriesID(id) {
      return fetch(this.lizard + 'RoomScene/SeriesID/' + id).then(function(response) {
        return response.json();
      });
    },
    getProductsBySeriesID: function getProductsBySeriesID(id) {
      return fetch(this.lizard + 'Product/Series/' + id).then(function (response) {
        return response.json();
      });
    },
    getProductsBySeriesIDs: function getProductsBySeriesIDs(ids) {
      var idsCsv = ids.join(',');

      return fetch(this.lizard + 'Product/SeriesList/' + idsCsv).then(function (response) {
        return response.json();
      });
    }
  };
})(jQuery);
