(function() {
  'use strict';

  // const log = debug('cham:calculator');
  var createComponent = Utilities.createComponent;

  CHAM.calculator = {
    modal: createComponent({
      constructor: function constructor () {
        var root = document.querySelector('section#MAIN');
        var div = root.appendChild(document.createElement('div'));
        div.id = 'calculator-modal';
        div.className = 'lightbox ghost-center active';
        this.events($(div));
        return this.render.bind(this, div);
      },
      render: function render(div) {
        var self = this;
        //var measurementSystem = 'metric'; //default checked at metric on load
        $('.noscroll').addClass('active');
        $('#calculator-modal').html(CHAM.templates.calculator());
      },
      events: function events(div) {
        var self = this;
        var measurementSystem = 'metric'; //default checked at metric on load

        console.log('clac check', div);
        div.on('click', 'button.close', function () {
          $('.noscroll').removeClass('active');
          div.remove();
        });
        div.on('click', '#checkboxImperial', function () {
          $('#checkboxMetric').prop('checked', false);
          measurementSystem = $(this).val();

          if ($('#checkboxMetric')[0].checked == false && $('#checkboxImperial')[0].checked == false) {
            $('#checkboxMetric').prop('checked', true);
            measurementSystem = 'metric';
          }
        });
        div.on('click', '#checkboxMetric', function () {
          $('#checkboxImperial').prop('checked', false);
          measurementSystem = $(this).val();

          if ($('#checkboxMetric')[0].checked == false && $('#checkboxImperial')[0].checked == false) {
            $('#checkboxMetric').prop('checked', true);
            measurementSystem = 'metric';
          }
        })

        div.on('click', 'li', function () {
          self.metric = $(this).data('meter');
          self.imperial = $(this).data('foot');
          if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
          } else if ($(this).hasClass('active')) {
            $(this).removeClass('active');
          }
        })
        div.on('click', 'div.size-side button', function () {
          var width = $('.width-size input').val();
          var height = $('.height-size input').val();
          var area = $('.area-size input').val();
          var result = 0;

          //Need to set up errors 
          //if (self.metric == null && self.imperial == null) $('div.total-bricks h1').hide(), $('div.total-bricks h3.error').text('Choose a product to calculate result.').show();
          if ($('.width-size input').val() != 0 && $('.height-size input').val() != 0) {
            $('.area-size input').val("");
            result = measurementSystem === 'metric' ? width * height * self.metric : width * height * self.imperial;
          }
          if ($('.area-size input').val() != 0) {
            $('.width-size input').val("");
            $('.height-size input').val("");
            result = measurementSystem === 'metric' ? area * self.metric : area * self.imperial;
          }
          if (isNaN(result)) result = 0;
          var model = CHAM.templates.calculatorResult(Math.ceil(result));
          $('.total-side').html(model);
        });

      }
    }),
    page: createComponent({
      constructor: function constructor () {},
      render: function render () {},
      events: function events () {}
    })
  };
})();
