(function () {
  'use strict';

  var log = debug('cham:menu');
  var createComponent = Utilities.createComponent;

  //product menus

  CHAM.menu = {
    brickStone: createComponent({
      constructor: function constructor() {
        var root = document.querySelector('div#menuProductTemplates');
        var div = document.createElement('div');
        div.id = 'brick-menu';
        div.setAttribute('data-type', 'brick');
        root.appendChild(div);   
        this.events($(root), div);
        return this.render.bind(this, root, div);
      },
      data: function data(){
        return new Promise(function (resolve) {
          if (CHAM.DATA.products) resolve(CHAM.DATA.products);
          CHAM.services.getProductsBySeriesIDs(_.map(CHAM.DATA.series, function (series) { return series.id; })).then(function (products) {
            products = _.groupBy(products, function (product) { return 's' + product.seriesID; });
            resolve(CHAM.DATA.products = products);
          });
        });
      },
      render: function render(root, div) {
        var self = this;
        var surfaces = _.filter(CHAM.DATA.surfaces, ['seriesid', '6448']);
        self.data().then(function (products) {
          products = { s6448: products.s6448, s4809: products.s4809 };
          div.innerHTML = CHAM.templates.menu.brickStone(products, surfaces, CHAM.store.getState());

          var li = $('ul#brickSurfaceMenu li');
         
            if ($('html').hasClass('android') && $('html').hasClass('tablet')) {
              li.addClass('no-hover');
            } else if ($('html').hasClass('android') && $('html').hasClass('tabletwide')) {
              li.addClass('no-hover');
            } else if ($('html').hasClass('android') && $('html').hasClass('tabletnarrow')) {
              li.addClass('no-hover');
            }
            if ($('html').hasClass('ipad')) {
              li.addClass('no-hover');
            }
            if ($('html').hasClass('mobile')) {
              li.addClass('no-hover');
            }
       
        });
      },
      events: function events(root, div) {
        var self = this;
        root.off('click', 'div#close-brick').on('click', 'div#close-brick', function () {
          $('div#brick-menu').removeClass('active');
          $('li#brick-toggle').removeClass('active');
          setTimeout(function () {
            $('div.mortar-products li.mortar').removeClass('hide');
            $('div.mortar-products li.mortar-msg').removeClass('active');
            $('div.mortar-products li.mortar').removeClass('active');
          });
        });
        root.off('mouseover', 'ul#brickSurfaceMenu li').on('mouseover', 'ul#brickSurfaceMenu li', function () {
          if ($('div#brick-menu').hasClass('active')) {
            if (!$('ul#trimSurfaceMenu li').hasClass('no-hover')) {
              $('div.ChamViz img.viz-highlight[data-surface="' + $(this).data('surfacenumber') + '"]').css('display', 'block');
            }
            if (!$('ul#brickSurfaceMenu li').hasClass('no-hover')) {
              $('div.ChamViz img.viz-highlight[data-surface="' + $(this).data('surfacenumber') + '"]').css('display', 'block');
            }
          }
        });
        root.off('mouseleave', 'ul#brickSurfaceMenu li').on('mouseleave', 'ul#brickSurfaceMenu li', function () {
          if ($('div#brick-menu').hasClass('active')) {
            if (!$('ul#trimSurfaceMenu li').hasClass('no-hover')) {
              $('div.ChamViz img.viz-highlight[data-surface="' + $(this).data('surfacenumber') + '"]').css('display', 'none');
            }
            if (!$('ul#brickSurfaceMenu li').hasClass('no-hover')) {
              $('div.ChamViz img.viz-highlight[data-surface="' + $(this).data('surfacenumber') + '"]').css('display', 'none');
            }
          }
        });
        root.off('click', 'ul#brickSurfaceMenu li').on('click', 'ul#brickSurfaceMenu li', function () {
          if ($(this).data('name') == 'All') {
            CHAM.stores.events.selectSurface.emit('All');
            if (!$(this).hasClass('active')) $(this).addClass('active'), $(this).siblings().removeClass();
            else $(this).removeClass();
          } else {
            CHAM.stores.events.selectSurface.emit($(this).data('surfacenumber'));
            if (!$(this).hasClass('active')) $(this).addClass('active'), $(this).siblings('#allBrickSurfaces').removeClass();
            else $(this).removeClass();
          }

          setTimeout(function () {
          // checks if BRICK and STONE have products before showing MORTAR products
                //$('div.mortar-products li.mortar').removeClass('hide');
                //$('div.mortar-products li.mortar-msg').removeClass('active');
                $('div#brick-menu div.product-list li').removeClass('active');
                var state = CHAM.store.getState();
                var all = state.surfacesSelected[0];
                var surfaces = state.surfacesSelected;
                var brickStone = state.BrickStone;

                if (all === 'All') {
                  _.each(brickStone, function (value, key) {
                    if (value === '' || value === '-1') {
                     
                      $('div.mortar-products li.mortar').addClass('hide');
                      $('div.mortar-products li.mortar-msg').addClass('active');
                    } else {
                      var idArray = [];
                  
                      for (var k in state.BrickStone) {
                        idArray.push(state.BrickStone[k]);
                      }
                   
                      if (_.uniqBy(idArray).length > 1) {
                        $('div.mortar-products li.mortar').addClass('hide');
                        $('div.mortar-products li.mortar-msg').addClass('active');

                      } else {
                        $('div.mortar-products li.mortar').removeClass('hide');
                        $('div.mortar-products li.mortar-msg').removeClass('active');
                        if ($('div.mortar-products').hasClass('active')) {
                          _.each(state.Mortar, function (value, key) {
                            $('div.mortar-products li.mortar[data-id="' + value + '"]').addClass('active');
                            if ($('div.mortar-products li.mortar').hasClass('active') && value != '') {
                              $('div.mortar-products li.mortar[data-id="' + value + '"]').get(0).scrollIntoView({ block: "nearest" });
                            }
                          });
                        }
                        var values = Object.keys(state.BrickStone).map(function (e) {
                          return state.BrickStone[e];
                        });
                        $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').addClass('active');
                        if ($('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').hasClass('active')) {
                          $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').get(0).scrollIntoView({ block: "nearest" });
                        }
                        
                      }
                    }
                  })
                } else if (surfaces === undefined || surfaces.length == 0){
                  $('div.mortar-products li.mortar').addClass('hide');
                  $('div.mortar-products li.mortar-msg').addClass('active');
                } else {
                  // if clicking surface buttons in mortar menu
                  if ($('div.mortar-products').hasClass('active')) {
                    _.each(surfaces, function (value, key) {
                      if (brickStone[value] === '' || brickStone[value] === '-1') {
                        $('div.mortar-products li.mortar').addClass('hide');
                        $('div.mortar-products li.mortar-msg').addClass('active');
                      } else {
                        $('div.mortar-products li.mortar').removeClass('hide');
                        $('div.mortar-products li.mortar-msg').removeClass('active');
                        $('div.mortar-products li.mortar[data-id="' + state.Mortar[value] + '"]').addClass('active');
                          if ($('div.mortar-products li.mortar').hasClass('active')) {
                            $('div.mortar-products li.mortar[data-id="' + state.Mortar[value] + '"]').get(0).scrollIntoView({ block: "nearest" });
                          } 
                      }
                    });
                  }
                  //clicking surface buttons in brick and stone menu
                  _.each(surfaces, function (value, key) {
                    if (brickStone[value] === '' || brickStone[value] === '-1') {
                      $('div.mortar-products li.mortar').addClass('hide');
                      $('div.mortar-products li.mortar-msg').addClass('active');
                    } else {                     
                      $('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').addClass('active');
                      if ($('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').hasClass('active')) {
                        $('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').get(0).scrollIntoView({ block: "nearest" });
                      }
                      
                    }
                  })
                }
          }, 100);
        });

        //dropdown filters COLOR 
        root.off('change', 'select.color, select.stone-color, select.block-color').on('change', 'select.color, select.stone-color, select.block-color', function () {
          // this determines which click events and data to listen for since BRICK and STONE are on same template. 
          if ($('div.stone-products').hasClass('active')) {
            var colorSelect = $('select.stone-color option:selected').val();
            var styleSelect = $('select.stone-style option:selected').val();
            var selectOptionC = $('select.stone-color option');
            var selectOptionS = $('select.stone-style option');
            $('select.stone-style option:not(:first)').hide();
          } else if ($('div.brick-products').hasClass('active'))  {
            var colorSelect = $('select.color option:selected').val();
            var styleSelect = $('select.style option:selected').val();
            var selectOptionC = $('select.color option');
            var selectOptionS = $('select.style option');
            $('select.style option:not(:first)').hide();
          } else if ($('div.block-products').hasClass('active')) {
            var colorSelect = $('select.block-color option:selected').val();
            var styleSelect = $('select.block-style option:selected').val();
            var selectOptionC = $('select.block-color option');
            var selectOptionS = $('select.block-style option');
            $('select.block-style option:not(:first)').hide();
          }

          // this will update list of options based on COLOR selection
            self.data().then(function (products) {
              products = { s6448: products.s6448 };
              _.each(products.s6448, function (i) {
                if (i.name === 'Clay Brick' && i.color === colorSelect) {
                 
                  $('option[value="' + i.style + '"]').show();
                  $('select.style option[value="' + styleSelect + '"]').prop('selected', true);
                }
                if (i.name === 'Concrete Stone' && i.color === colorSelect) {
                  $('option[value="' + i.descriptor + '"]').show();
                  $('select.stone-style option[value="' + styleSelect + '"]').prop('selected', true);
                }
              });
            });

          //controls what products are hidden and shown
          if (colorSelect === 'COLOR' && styleSelect === 'STYLE') {
            $('div.product-list li').removeClass('hidden');
            selectOptionC.show();
            selectOptionS.show();
          } else if (styleSelect === 'STYLE' && colorSelect !== 'COLOR') {
            $('div.product-list li').removeClass('hidden');
            $('div.product-list li').addClass('hidden');
            $('div.product-list li[data-color="' + colorSelect + '"]').removeClass('hidden');
           
          } else if (colorSelect === 'COLOR' && styleSelect !== 'STYLE' ) {
            $('div.product-list li').removeClass('hidden');
            $('div.product-list li').addClass('hidden');
            $('div.product-list li[data-style="' + styleSelect + '"]').removeClass('hidden');
            selectOptionS.show();

          } else if (colorSelect !== 'COLOR' && styleSelect !== 'STYLE') {
            $('div.product-list li').addClass('hidden');
            $('div.product-list li[data-style="' + styleSelect + '"][data-color="' + colorSelect + '"]').removeClass('hidden');
          }
        });
        //dropdown filters STYLE
        root.off('change', 'select.style, select.stone-style, select.block-style').on('change', 'select.style, select.stone-style, select.block-style', function () {
          // this determines which click events and data to listen for since BRICK and STONE are on same template. 
          if ($('div.stone-products').hasClass('active')) {
            var colorSelect = $('select.stone-color option:selected').val();
            var styleSelect = $('select.stone-style option:selected').val();
            var selectOptionC = $('select.stone-color option');
            var selectOptionS = $('select.stone-style option');
            $('select.stone-color option:not(:first)').hide();
          } else if ($('div.brick-products').hasClass('active')) {
            var colorSelect = $('select.color option:selected').val();
            var styleSelect = $('select.style option:selected').val();
            var selectOptionC = $('select.color option');
            var selectOptionS = $('select.style option');
            $('select.color option:not(:first)').hide();
          } else if ($('div.block-products').hasClass('active')) {
            var colorSelect = $('select.block-color option:selected').val();
            var styleSelect = $('select.block-style option:selected').val();
            var selectOptionC = $('select.block-color option');
            var selectOptionS = $('select.block-style option');
            $('select.block-color option:not(:first)').hide();
          }
          // this will update list of options based on STYLE selection
          self.data().then(function (products) {
            products = { s6448: products.s6448 };
            _.each(products.s6448, function (i) {
              if (i.name === 'Clay Brick' && i.style === styleSelect) {
                $('option[value="' + i.color + '"]').show();
                $('select.color option[value="' + colorSelect + '"]').prop('selected', true);
              }

              if (i.name === 'Concrete Stone' && i.descriptor === styleSelect) {
                $('option[value="' + i.color + '"]').show();
                $('select.stone-color option[value="' + colorSelect + '"]').prop('selected', true);
              }
            });
          });
          //controls what products are hidden and shown
          if (colorSelect === 'COLOR' && styleSelect === 'STYLE') {
            $('div.product-list li').removeClass('hidden');
            selectOptionC.show();
            selectOptionS.show();
          } else if (colorSelect === 'COLOR' && styleSelect !== 'STYLE') {
            $('div.product-list li').removeClass('hidden');
            $('div.product-list li').addClass('hidden');
            $('div.product-list li[data-style="' + styleSelect + '"]').removeClass('hidden');

          } else if (styleSelect === 'STYLE' && colorSelect !== 'COLOR') {
            $('div.product-list li').removeClass('hidden');
            $('div.product-list li').addClass('hidden');
            $('div.product-list li[data-color="' + colorSelect + '"]').removeClass('hidden');
            selectOptionC.show();

          } else if (colorSelect !== 'COLOR' && styleSelect !== 'STYLE') {
            $('div.product-list li').addClass('hidden');
            $('div.product-list li[data-style="' + styleSelect + '"][data-color="' + colorSelect + '"]').removeClass('hidden');
          }
        });
        root.off('click', 'li#brick-button').on('click', 'li#brick-button', function () {
          $('div#brick-menu div.product-list li').removeClass('active');
          var state = CHAM.store.getState();
          var surfaces = state.surfacesSelected;
          var brickStone = state.BrickStone;
          setTimeout(function () {
            if (state.surfacesSelected[0] == 'All') {
              var idArray = [];
              for (var k in state.BrickStone) {
                idArray.push(state.BrickStone[k]);
              }
              if (_.uniqBy(idArray).length > 1) {
                console.log('nothing matches');
              } else {
                var values = Object.keys(state.BrickStone).map(function (e) {
                return state.BrickStone[e];
              });
                $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').addClass('active');
                if ($('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').hasClass('active')) {
                  $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').get(0).scrollIntoView({ block: "nearest" });
                }
              }
            } else {
              _.each(surfaces, function (value, key) {
                if (brickStone[value] !== '' || brickStone[value] !== '-1') {
                  $('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').addClass('active');
                  if ($('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').hasClass('active')) {
                    $('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').get(0).scrollIntoView({ block: "nearest" });
                  }
                }
              })
            }
          });
          if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
          }
          $('.brick-products').addClass('active');
          $('.stone-products').removeClass('active');
          $('.block-products').removeClass('active');
          $('.mortar-products').removeClass('active');
          $('div#brick-menu div.product-list li').removeClass('active');

          // resets the drop down filters for brick
          $('select.style option[value="STYLE"]').prop('selected', true);
          $('select.color option[value="COLOR"]').prop('selected', true);
          $('div.product-list li').removeClass('hidden');
        });
        root.off('click', 'li#stone-button').on('click', 'li#stone-button', function () {
          $('div#brick-menu div.product-list li').removeClass('active');
          var state = CHAM.store.getState();
          var surfaces = state.surfacesSelected;
          var brickStone = state.BrickStone;
          setTimeout(function () {
            if (state.surfacesSelected[0] == 'All') {
              var idArray = [];
              for (var k in state.BrickStone) {
                idArray.push(state.BrickStone[k]);
              }
              if (_.uniqBy(idArray).length > 1) {
                console.log('nothing matches');
              } else {
                var values = Object.keys(state.BrickStone).map(function (e) {
                  return state.BrickStone[e];
                });
                $('div#brick-menu div.product-list li[data-id="' +values[0] + '"]').addClass('active');
                if ($('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').hasClass('active')) {
                  $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').get(0).scrollIntoView({ block: "nearest" });
                }
              }
            } else {
              _.each(surfaces, function (value, key) {
                if (brickStone[value] !== '' || brickStone[value] !== '-1') {
                  $('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').addClass('active');
                  if ($('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').hasClass('active')) {
                    $('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').get(0).scrollIntoView({ block: "nearest" });
                  }
                }
              })
            }
          });
          if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
          }
          $('.stone-products').addClass('active');
          $('.brick-products').removeClass('active');
          $('.block-products').removeClass('active');
          $('.mortar-products').removeClass('active');
          // resets the drop down filters for stone
          $('select.stone-style option[value="STYLE"]').prop('selected', true);
          $('select.stone-color option[value="COLOR"]').prop('selected', true);
          $('div.product-list li').removeClass('hidden');
        });

        root.off('click', 'li#block-button').on('click', 'li#block-button', function () {
          $('div#brick-menu div.product-list li').removeClass('active');
          var state = CHAM.store.getState();
          var surfaces = state.surfacesSelected;
          var brickStone = state.BrickStone;
          setTimeout(function () {
            if (state.surfacesSelected[0] == 'All') {
              var idArray = [];
              for (var k in state.BrickStone) {
                idArray.push(state.BrickStone[k]);
              }
              if (_.uniqBy(idArray).length > 1) {
                console.log('nothing matches');
              } else {
                var values = Object.keys(state.BrickStone).map(function (e) {
                  return state.BrickStone[e];
                });
                $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').addClass('active');
                if ($('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').hasClass('active')) {
                  $('div#brick-menu div.product-list li[data-id="' + values[0] + '"]').get(0).scrollIntoView({ block: "nearest" });
                }
              }
            } else {
              _.each(surfaces, function (value, key) {
                if (brickStone[value] !== '' || brickStone[value] !== '-1') {
                  $('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').addClass('active');
                  if ($('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').hasClass('active')) {
                    $('div#brick-menu div.product-list li[data-id="' + brickStone[value] + '"]').get(0).scrollIntoView({ block: "nearest" });
                  }
                }
              })
            }
          });
          if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
          }
          $('.block-products').addClass('active');
          $('.stone-products').removeClass('active');
          $('.brick-products').removeClass('active');
          $('.mortar-products').removeClass('active');
          // resets the drop down filters for block
          $('select.block-style option[value="STYLE"]').prop('selected', true);
          $('select.block-color option[value="COLOR"]').prop('selected', true);
          $('div.product-list li').removeClass('hidden');
        });
        root.off('click', 'li#mortar-button').on('click', 'li#mortar-button', function () {         
          // checks if BRICK and STONE have products before showing MORTAR products
          var state = CHAM.store.getState();  
          var all = state.surfacesSelected[0];
          var surfaces = state.surfacesSelected;
          var brickStone = state.BrickStone;

          if (all === 'All') {
            _.each(brickStone, function (value, key) {
              if (value === '' || value === '-1') {
                $('div.mortar-products li.mortar').addClass('hide');
                $('div.mortar-products li.mortar-msg').addClass('active');
              } else {
                var idArray = [];
                
                for (var k in state.BrickStone) {
                  idArray.push(state.BrickStone[k]);
                }
              
                if (_.uniqBy(idArray).length > 1) {
                  $('div.mortar-products li.mortar').addClass('hide');
                  $('div.mortar-products li.mortar-msg').addClass('active');
                  
                } else {
                  $('div.mortar-products li.mortar').removeClass('hide');
                  $('div.mortar-products li.mortar-msg').removeClass('active');
                  _.each(state.Mortar, function (value, key) {
                    $('div.mortar-products li.mortar[data-id="' + value + '"]').addClass('active');
                    if ($('div.mortar-products li.mortar').hasClass('active') && value != '') {
                      $('div.mortar-products li.mortar[data-id="' + value + '"]').get(0).scrollIntoView({ block: "nearest" });
                    } 
                  });

                }
              }
            })
          } else if (surfaces === undefined || surfaces.length == 0) {
            $('div.mortar-products li.mortar').addClass('hide');
            $('div.mortar-products li.mortar-msg').addClass('active');
          } else {
            console.log(state);
            _.each(surfaces, function (value, key) {
              if (state.BrickStone[value] === '' || state.BrickStone[value] === '-1') {
                $('div.mortar-products li.mortar').addClass('hide');
                $('div.mortar-products li.mortar-msg').addClass('active');
              } else {
                $('div.mortar-products li.mortar').removeClass('hide');
                $('div.mortar-products li.mortar-msg').removeClass('active');
                $('div.mortar-products li.mortar[data-id="' + state.Mortar[value] + '"]').addClass('active');
                if ($('div.mortar-products li.mortar').hasClass('active')) {
                  $('div.mortar-products li.mortar[data-id="' + state.Mortar[state.surfacesSelected] + '"]').get(0).scrollIntoView({ block: "nearest" });
                } 
              }
            })
          } 

          //controls button color and menu funtions
          if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
          }
          $('.mortar-products').addClass('active');
          $('.brick-products').removeClass('active');     
          $('.stone-products').removeClass('active');
          $('.block-products').removeClass('active');
        });
     
        //poduct selection
        root.off('click', 'div#brick-menu div.product-list li').on('click', 'div#brick-menu div.product-list li', function () {
          var type = $(this).parent().data('type');
          CHAM.stores.events.addProduct.emit({ type: _.capitalize(type), id: $(this).data('id') });
          CHAM.selStores.events.updateSel.emit('');
          $(this).addClass('active');
          $(this).siblings().removeClass('active');

          $('div#brick-menu').removeClass('active');
          $('li#brick-toggle').removeClass('active');
     
          $('div.mortar-products li.mortar').removeClass('hide');
          $('div.mortar-products li.mortar-msg').removeClass('active');
        });    
      }
    }),
    trim: createComponent({
      constructor: function constructor() {
        var root = document.querySelector('div#menuProductTemplates');
        var div = document.createElement('div');
        div.id = 'trim-menu';
        div.setAttribute('data-type', 'trim');
        root.appendChild(div);
        this.events($(root));
        return this.render.bind(this, root, div);
      },
      data: function data() {
        return new Promise(function (resolve) {
          if (CHAM.DATA.products) resolve(CHAM.DATA.products);
          CHAM.services.getProductsBySeriesIDs(_.map(CHAM.DATA.series, function (series) { return series.id; })).then(function (products) {
            products = _.groupBy(products, function (product) { return 's' + product.seriesID; });
            resolve(CHAM.DATA.products = products);
          });
        });
      },
      render: function render(root, div) {
        var self = this;
        var surfaces = [];
        _.each(CHAM.DATA.surfaces, function (surface) {
          if (surface.type != 'Roof' && surface.type != 'Wall') surfaces.push(surface);
        });
        self.data().then(function (products) {
          products = { s204: products.s204 };
          div.innerHTML = CHAM.templates.menu.trim(products, surfaces, CHAM.store.getState());
          var li = $('ul#trimSurfaceMenu li');

            if ($('html').hasClass('android') && $('html').hasClass('tablet')) {
              li.addClass('no-hover');
            } else if ($('html').hasClass('android') && $('html').hasClass('tabletwide')) {
              li.addClass('no-hover');
            } else if ($('html').hasClass('android') && $('html').hasClass('tabletnarrow')) {
              li.addClass('no-hover');
            }
            if ($('html').hasClass('ipad')) {
              li.addClass('no-hover');
            }
            if ($('html').hasClass('mobile')) {
              li.addClass('no-hover');
            }
          
        });
      },
      events: function events(root) {
        root.off('click', 'div#close-trim').on('click', 'div#close-trim', function () {
          $('div#trim-menu').removeClass('active');
          $('li#trim-toggle').removeClass('active');
        });
        //surfaceSelection
        root.off('click', 'ul#trimSurfaceMenu li').on('click', 'ul#trimSurfaceMenu li', function () {
          if ($(this).attr('id') == 'allTrimSurfaces') {
            CHAM.stores.events.selectSurface.emit('All');
            if (!$(this).hasClass('active')) $(this).addClass('active'), $(this).siblings().removeClass();
            else $(this).removeClass();
          } else {
            CHAM.stores.events.selectSurface.emit($(this).data('surfacenumber'));
            if (!$(this).hasClass('active')) $(this).addClass('active'), $('li#allTrimSurfaces').removeClass();
            else $(this).removeClass();
          }

          setTimeout(function () {
            $('div.trim-products li').removeClass('active');
            var state = CHAM.store.getState();
            var surfaces = state.surfacesSelected;
            var trim = state.Trim;
 
            if (state.surfacesSelected[0] == 'All') {
              var idArray = [];
              for (var k in state.Trim) {
                idArray.push(state.Trim[k]);
              }
              if (_.uniqBy(idArray).length > 1) {
            
                console.log('nothing matches');
              } else {
                var values = Object.keys(state.Trim).map(function (e) {
                  return state.Trim[e];
                });
                $('div.trim-products li[data-id="' + values[0] + '"]').addClass('active');
                if ($('div.trim-products li').hasClass('active')) {
                  $('div.trim-products li[data-id="' + values[0] + '"]').get(0).scrollIntoView({ block: "nearest" });
                }
              }
            } else if (surfaces === undefined || surfaces.length == 0) {
              $('div.trim-products li').removeClass('active');
            } else {
              _.each(surfaces, function (value, key) {
                if (trim[value] === '' || trim[value] === '-1') {
                  $('div.trim-products li').removeClass('active');
                } else {
                  $('div.trim-products li[data-id="' + trim[value] + '"]').addClass('active');
                  if ($('div.trim-products li').hasClass('active')) {
                    $('div.trim-products li[data-id="' + trim[value] + '"]').get(0).scrollIntoView({ block: "nearest" });
                  }
                }
              })
            }
          }, 100);
        });

        //product selection
        root.off('click', 'div.trim-products li').on('click', 'div.trim-products li', function () {
          var type = 'Trim';
          CHAM.stores.events.addProduct.emit({ type: type, id: $(this).data('id') });
          CHAM.selStores.events.updateSel.emit('');
          $(this).addClass('active');
          $(this).siblings().removeClass('active');
          $('div#trim-menu').toggleClass('active');
          $('li#trim-toggle').toggleClass('active');
        });
      }
    }),
    roof: createComponent({
      constructor: function constructor() {
        var root = document.querySelector('div#menuProductTemplates');
        var div = document.createElement('div');
        div.id = 'roof-menu';
        div.setAttribute('data-type', 'roof');
        root.appendChild(div);
        this.events($(root), div);
        return this.render.bind(this, root, div);
      },
      data: function data() {
        return new Promise(function (resolve) {
          if (CHAM.DATA.products) resolve(CHAM.DATA.products);
          CHAM.services.getProductsBySeriesIDs(_.map(CHAM.DATA.series, function (series) { return series.id; })).then(function (products) {
            products = _.groupBy(products, function (product) { return 's' + product.seriesID; });
            resolve(CHAM.DATA.products = products);
          });
        });
      },
      render: function render(root, div) {
        var self = this;
        var surfaces = [];
        _.each(CHAM.DATA.surfaces, function (surface) {
          if (surface.type == 'Roof') surfaces.push(surface);
        });
        self.data().then(function (products) {
          products = { s6285: products.s6285 };
          div.innerHTML = CHAM.templates.menu.roof(products, CHAM.store.getState(), surfaces);
        });
      },
      events: function events(root) {
        root.off('click', 'div#close-roof').on('click', 'div#close-roof', function () {
          $('div#roof-menu').toggleClass('active');
          $('li#roof-toggle').toggleClass('active');
          $('div.mortar-products li.mortar').removeClass('hide');
          $('div.mortar-products li.mortar-msg').removeClass('active');
        });
        //product selection
        root.off('click', 'ul#roof-products li').on('click', 'ul#roof-products li', function () {
          var type = 'Roof';
          CHAM.stores.events.addProduct.emit({ type: type, id: $(this).data('id') });
          CHAM.selStores.events.updateSel.emit('');
          $(this).addClass('active');
          $(this).siblings().removeClass('active');
          $('div#roof-menu').removeClass('active');
          $('li#roof-toggle').removeClass('active');
        });
      }
    })
  };

})();
