
(function () {
  'use strict';

  var log = debug('cham:header');
  var createComponent = Utilities.createComponent;

  CHAM.header = createComponent({
    constructor: function constructor() {
      var root = document.querySelector('header');
      var div = root.appendChild(document.createElement('div'));
      div.className = 'visualizer-wrapper';
      this.events($(root), div);
      return this.render.bind(this, root, div);
    },
    render: function render(root, div) {
      var self = this;
      div.innerHTML = CHAM.templates.header();
      CHAM.projects.init();
    },
    events: function events(root, div) {
      var self = this;
      root.off('click', 'div#calc-button').on('click', 'div#calc-button', function () {
        CHAM.calculator.modal().render();
      });
      root.off('click', 'div#upload').on('click', 'div#upload', function () {
        CHAM.upload.init();
      });
      root.off('click', 'div#home').on('click', 'div#home', function () {
        CHAM.router.navigate('/');
        CHAM.stores.events.resetState.emit();
      });
    }

  })
})();
