(function () {
  'use strict';
  var log = debug('cham:stores');

  CHAM.stores = {
    init: {
      BrickStone: {},
      Mortar: {},
      surfacesSelected: ['All']
    },
    create: function create() {
      return Stores.AggregateStore(this.events, this.reducers(), this.init);
    },
    events: {
      updateSurfaces: Observable(),
      addProduct: Observable(),
      selectSurface: Observable(),
      generateSavedStore: Observable(),
      resetSurfaces: Observable(),
      resetState: Observable()
    },
    //runs when the initial state is created - dependent on surfaces
    populateSurfaces: function populateSurfaces(surfaces) {
      var clone = _.assign({}, CHAM.store.getState());
      if (_.find(surfaces, ['seriesid', '204'])) clone.Trim = {};
      if (_.find(surfaces, ['seriesid', '6285'])) clone.Roof = {};
      _.each(surfaces, function (surface) {
        if (surface.seriesid == '6448') clone.BrickStone[surface.specifiersurfacenumber] = '', clone.Mortar[surface.specifiersurfacenumber] = '';
        if (surface.seriesid == '204') clone.Trim[surface.specifiersurfacenumber] = '';
        if (surface.seriesid == '6285') clone.Roof[surface.specifiersurfacenumber] = '';
      });
      CHAM.stores.events.updateSurfaces.emit(clone);
    },
    updateSurfaces: function updateSurfaces(surfaceArray, surface) {
      var newSurfaces = [];
      if (surfaceArray[0] == 'All' && surface == 'All') return [];
      if (surfaceArray[0] != 'All' && surface == 'All') return ['All'];
      if ((surfaceArray[0] == 'All' || surfaceArray[0] == null) && _.indexOf(surfaceArray, surface) == -1) return [surface];
      if (surfaceArray[0] != 'All' && _.indexOf(surfaceArray, surface) == -1) {
        surfaceArray.push(surface);
      } else {
        for (var key in surfaceArray) {
          if (surfaceArray[key] != surface) {
            newSurfaces.push(surfaceArray[key]);
          }
        }
        return newSurfaces;
      };
      return surfaceArray;
    },
    //runs when a product is added
    updateProducts: function updateProducts(currentState, eventPayload) {
      var clone = _.cloneDeep(currentState);
      if (eventPayload.type == 'Stone' || eventPayload.type == 'Brick' || eventPayload.type == 'Block') eventPayload.type = 'BrickStone';

      //can only apply one product to roof surfaces for Image Mapper
      if (eventPayload.type == 'Roof') { 
        for (var key in clone[eventPayload.type]) {
          clone[eventPayload.type][key] = eventPayload.id;
        }
      }
      if (clone.surfacesSelected[0] == 'All') {
        for (var key in clone[eventPayload.type]) {
          clone[eventPayload.type][key] = eventPayload.id;
        }
      } else {
        _.each(clone.surfacesSelected, function (surface) {
          clone[eventPayload.type][surface] = eventPayload.id;
        });
      }
      return clone;
    },
    //this runs when the project is shared, the page is refreshed or a saved project is opened
    generateStateFromSel: function generateStateFromSel(surfaces) {
      var sel = (CHAM.selStore.getState()).split(',');
      var clone = _.assign({}, CHAM.store.getState());
      var roof = [];
      var trim = [];
      var brick = [];
      _.each(surfaces, function (surf) {
        if (surf.seriesid == '204') trim.push(surf);
        if (surf.seriesid == '6448') brick.push(surf);
        if (surf.seriesid == '6285') roof.push(surf);
      });
      _.each(trim, function (surface) {
        clone.Trim[surface.specifiersurfacenumber] = sel[surface.specifiersurfacenumber - 1] || '-1';
      });
      _.each(roof, function (surface) {
        clone.Roof[surface.specifiersurfacenumber] = sel[surface.specifiersurfacenumber - 1] || '-1';
      });
      _.each(brick, function (surface) {
        if (_.endsWith(sel[surface.specifiersurfacenumber - 1], ')')) {
          clone.Mortar[surface.specifiersurfacenumber] = String(sel[surface.specifiersurfacenumber - 1].slice(sel[surface.specifiersurfacenumber - 1].indexOf('(') + 1, sel[surface.specifiersurfacenumber - 1].length - 1)) || '-1';
          clone.BrickStone[surface.specifiersurfacenumber] = String(sel[surface.specifiersurfacenumber - 1].slice(0, 7)) || '-1';
        } else {
          clone.BrickStone[surface.specifiersurfacenumber] = sel[surface.specifiersurfacenumber - 1] || '-1';
        }
      });
      CHAM.stores.events.generateSavedStore.emit(clone);
    },
    clearState: function clearState() {
      return {
        BrickStone: {},
        Mortar: {},
        surfacesSelected: ['All']
      }
    },
    reducers: function reducers() {
      return {
        updateSurfaces: function updateSurfaces(currentState, eventPayload) {
          return eventPayload;
        },
        addProduct: function addProduct(currentState, eventPayload) {
          return CHAM.stores.updateProducts(currentState, eventPayload);
        },
        selectSurface: function selectSurface(currentState, eventPayload) {
          var clone = _.assign({}, currentState);
          clone.surfacesSelected = CHAM.stores.updateSurfaces(currentState.surfacesSelected, eventPayload);
          return clone;
        },
        generateSavedStore: function generateSavedStore(currentState, eventPayload) {
          return eventPayload;
        },
        resetSurfaces: function resetSurfaces(currentState, eventPayload) {
          var clone = _.assign({}, currentState);
          clone.surfacesSelected = [eventPayload];
          return clone;
        },
        resetState: function resetState() {
          return CHAM.stores.clearState();
        }
      }
    }
  };

  //every time a product is clicked update this state
  CHAM.selStores = {
    init: '',
    create: function create() {
      return Stores.EventStore(this.events, this.reducers(), this.init);
    },
    events: {
      updateSel: Observable()
    },
    generateSelString: function generateSelString() {
      var clone = _.cloneDeep(CHAM.store.getState());
      var surfaces = CHAM.DATA.surfaces;
      var sel = '';
      _.each(surfaces, function (surface) {
        if (surface.type == 'Wall') {
          if (clone.Mortar[surface.specifiersurfacenumber] != '-1' && clone.Mortar[surface.specifiersurfacenumber] != '') {
            sel += (String(clone.BrickStone[surface.specifiersurfacenumber]) || '-1') + '(' + String(clone.Mortar[surface.specifiersurfacenumber]) + '),';
          } else {
            sel += (String(clone.BrickStone[surface.specifiersurfacenumber]) || '-1') + ',';
          }
        }
        if (surface.type == 'Roof') sel += (String(clone.Roof[surface.specifiersurfacenumber]) || '-1') + ',';
        if (surface.type != 'Roof' && surface.type != 'Wall') sel += (String(clone.Trim[surface.specifiersurfacenumber]) || '-1') + ',';
      });
      sel = _.trimEnd(sel, ',');
      return sel;
    },
    reducers: function reducers() {
      return {
        updateSel: function updateSel(currentState, eventPayload) {
          if (eventPayload == '') return CHAM.selStores.generateSelString();
          else return eventPayload;
        }
      }
    }
  };
})();
