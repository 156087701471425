var $ = jQuery.noConflict(),
    CHAM = CHAM || {};

(function ($) {
  'use strict';

  var log = debug('cham:feedback');

  CHAM.feedback = {
    init: function () {
      this.modal = $('#feedback-modal');
      this.form = this.modal.find('form');
      this.validator = Forms.FormValidator(this.form, this.formRules);
    },
    formRules: {
      email: [{ type: 'required', message: 'An email address is required.' }, { type: 'email', message: 'Please enter a valid email address.' }],
      message: [{ type: 'required', message: 'A message is required.' }]
    },
    events: function () {
      var self = this;

      $('.feedback').on('click', function () {
        self.modal.fadeIn();
      });

      self.modal.on('click', '.close, .cancel', function () {
        self.modal.fadeOut().promise().done(function () {
          self.validator.resetForm();
        });
      });

      self.form.on('submit', function (e) {
        e.preventDefault();
        if (!self.validator.validateAndPrintMessages())
          return;

        $.post('/services/feedback.ashx', self.validator.gatherFormValues());
        self.modal.fadeOut().promise().done(function () {
          self.validator.resetForm();
        });
      });
    }
  };
})(jQuery);
